<template>
  <div class="w-full bg-gray-800 p-4">
    <div
      class="
        w-full
        max-w-6xl
        mx-auto
        grid
        gap-8
        md:gap-2
        grid-cols-2
        md:grid-cols-2
        lg:grid-cols-4
      "
    >
      <div>
        <h3 class="text-blue-400 mb-2">
          <b>Academy</b>
        </h3>
        <div class="text-white flex flex-col text-xs md:text-base">
          <router-link to="/branches"> Our Branches </router-link>
          <router-link to="/courses"> Our Courses </router-link>
        </div>
      </div>

      <div class="text-right lg:text-left">
        <h3 class="text-blue-400 mb-2">
          <b>Explore</b>
        </h3>
        <div class="text-white flex flex-col text-xs md:text-base">
          <router-link to="#"> Gallery </router-link>
          <router-link to="#"> E-library </router-link>
        </div>
      </div>

      <div class="col-span-2 md:col-span-1 text-center md:text-left">
        <h3 class="text-blue-400 mb-2">
          <b>Company</b>
        </h3>
        <div class="text-white flex flex-col text-xs md:text-base">
          <router-link to="/terms-and-conditions" class="mb-2 md:mb-0"> Terms and Condition </router-link>
          <router-link to="/refund-policy"> Refund Policy </router-link>
        </div>
      </div>

      <div class="text-center md:text-right col-span-2 md:col-span-1">
        <h3 class="text-blue-400 mb-2">
          <b>Contact</b>
        </h3>
        <div class="text-white flex flex-col text-xs md:text-base mb-4 md:mb-0">
          <p class="mb-2 md:mb-0">{{ $store.state.appData.branchEmail }}</p>
          <p>{{ $store.state.appData.branchPhone }}</p>
        </div>
      </div>

      
      
      
      <div class="col-span-full" v-if="$store.state.appData.hasSslcommerzImage">
        <!-- <img src="https://retina-storage.s3.ap-southeast-1.amazonaws.com/public/uploads/files/payment-banner-horizontal-three-row-icons.jpg" > -->
        <!-- <img src="https://retina-storage.s3.ap-southeast-1.amazonaws.com/public/uploads/files/payment-banner-horizontal-one-row-icons.jpg" > -->
         <div class="hidden md:block">
            <img 
              src="https://retina-storage.s3.ap-southeast-1.amazonaws.com/public/uploads/files/payment-banner-horizontal-medium.jpg" 
            />
         </div>
         <div class="block md:hidden">
            <img 
              src="https://retina-storage.s3.ap-southeast-1.amazonaws.com/public/uploads/files/payment-banner-vertical-four-column.jpg" 
            />
         </div>
      </div>

    </div>

  </div>

  <div class="w-full bg-blue-500 text-white py-3">
    <div class="w-full max-w-6xl mx-auto">
      <p class="text-center text-xs md:text-base">
        &copy; <span v-text="new Date().getFullYear()"></span> retina.academy |
        All right reserved | <span>{{ $store.state.appData.name }}</span>
      </p>
    </div>
  </div>
</template>