function setModel( model ){

    if( typeof model == "function" ) {

        let d = null;

        model.setData = function ( data ){
            d = data;
            return model;
        };

        model.collection = ( items ) => {

            if( Array.isArray( items )) {
                if( d )
                    return items.map( (item,i) => model( item, i, d ))
                return items.map( model )
            }
        }

    }

    return model;
}

export { setModel };