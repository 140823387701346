import {isLoggedIn} from "../store/axios";
import NotFound from "../views/public/not-found";
import {authenticateBeforeNext} from "./functions";

const beforeJoin = ( to, from, next ) => {
    if( isLoggedIn( ) ) {
        next({ path: '/my/dashboard' })
    }
    next();
}

export default [
    {
        path: '',
        name: 'home',
        component: () => import("../views/public/home"),
        meta: {
            title: 'Welcome to  ${name}'
        }
    },
    {
        path: 'branches',
        name: 'branch',
        component: () => import("../views/public/branch"),
        meta: {
            title: 'Branches | ${name}'
        }
    },
    {
        path: 'courses',
        name: 'course',
        component: () => import("../views/public/course"),
        children: [
            {
                path: ':programId/:courseName?/:batchName?',
                name: ':program-details',
                component: () => import("../views/public/course/program-details"),
            },

            {
                path: ':programId/:courseName?/:batchName?/course-contents',
                name: ':program-content',
                component: () => import("../views/public/course/course-contents")
            },

            {
                path: ':programId/:courseName?/:batchName?/enrollment',
                name: ':program-enrollment',
                component: () => import("../views/public/course/program-enrollment"),
                beforeEnter: authenticateBeforeNext
            },
        ],
        meta: {
            title: "Courses | ${name}"
        }
    },


    {
        path: 'terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import( "../views/public/terms-and-condition/Index.vue" ),
        meta: {
            title: "Terms and condition | ${name}"
        }
    },
    {
        path: 'refund-policy',
        name: 'refund-policy',
        component: () => import("../views/public/refund-policy/Index.vue"),
        meta: {
            title: "Privacy policy | ${name}"
        }
    },
    {
        path: 'about',
        name: 'about',
        component: () => import("../views/public/about"),
        meta: {
            title: "About Us | ${name}"
        }
    },

    
    {
        path: 'contact',
        name: 'contact',
        component: () => import("../views/public/contact"),
        meta: {
            title: "Contact Us | ${name}"
        }
    },
    {
        path: 'join',
        name: 'join',
        component: () => import("../views/auth/student/join"),
        beforeEnter: beforeJoin,
        meta: {
            title: "Join | ${name}"
        }
    },
    {
        path: 'login',
        name: 'login',
        component: () => import("../views/auth/student/login"),
        beforeEnter: beforeJoin,
        meta: {
            title: "Login | ${name}"
        }
    },
    {
        path: 'confirm',
        name: 'confirm',
        component: () => import("../views/auth/student/confirm"),
        beforeEnter: beforeJoin,
        meta: {
            title: "Confirm | ${name}"
        }
    },
    {
        path: 'forgot-password',
        name: 'forgot-password',
        component: () => import( "../views/auth/student/forget-password" ),
        beforeEnter: beforeJoin,
        meta: {
            title: "Forgot password | ${name}"
        }
    },
    {
        path: 'register',
        name: 'register',
        component: () => import("../views/auth/student/register"),
        beforeEnter: beforeJoin,
        meta: {
            title: "Registration | ${name}"
        }
    },
    {
        path: '/:pathMatch(.*)*', //will match everything and put it under `$route.params.pathMatch`
        name: 'not-found',
        component: NotFound,
        meta: {
            title: "404 Not found | ${name}"
        }
    },
    {
        path: 'courses/:program_id/schedules',
        name: 'public-courses-schedules',
        component: () => import( "../views/student/schedule" ),
        meta: {
            title: "Course Schedules | ${name}"
        }
    },
    {
        path: 'student-reviews',
        name: 'student-reviews',
        component: () => import("../views/public/review"),
        meta: {
            title: "Student Reviews"
        },
        children: [
            {
                path: ':id',
                name: ':student-reviews-view',
                component: () => import("../views/public/review/student-review-single-view"),
            }
        ],
    },
    {
        path: 'notices',
        name: 'notices',
        component: () => import("../views/public/notices"),
        meta: {
            title: "Notices"
        },
        children: [
            {
                path: ':id',
                name: ':notice-view',
                component: () => import("../views/public/notices/notice-view"),
            }
        ]
    },
    {
        path: 'teachers-panel',
        name: 'teachers-panel',
        component: () => import("../views/public/teacher/index.vue"),
        meta: {
            title: "Teachers Panel"
        },
        children: [
            {
                path: ':id',
                name: ':teacher-view',
                component: () => import("../views/public/teacher/teacher-view.vue"),
            }
        ]
    },
    {
        path: 'faq',
        name: 'faq',
        component: () => import("../views/public/faq/index.vue"),
        meta: {
            title: "FAQ"
        }
    },
    {
        path: 'saq',
        name: 'saq',
        component: () => import("../views/public/saq"),
        meta: {
            title: "SAQ"
        },
        children: [
            {
                path: ':id',
                name: ':saq-view',
                component: () => import("../views/public/saq/saq-view"),
            }
        ]
    },
    {
        path: 'data-collections/:type',
        name: 'data-collections',
        component: () => import("../views/public/data-collections"),
        meta: {
            title: "Data Collection"
        },
        children: [
            {
                path: ':id',
                name: 'data-collections-view',
                component: () => import("../views/public/data-collections/show"),
            },
            {
                path: ':id/download',
                name: 'data-collections-download',
                component: () => import("../views/public/data-collections/Download"),
            }
        ]
    },
    {
        path: 'payment/choose-method',
        name: 'payment-methods',
        component: () => import("../views/public/data-collections/components/payment-process"),
        meta: {
            title: 'Choose Payment Process'
        }
    },
    {
        path: 'test',
        name: 'method',
        component: () => import("../views/public/data-collections/components/transaction-confirm"),
        meta: {
            title: 'Transaction Confirm'
        }
    },
]