<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.25 24.75">
      <g
        id="Icon_ionic-ios-options"
        data-name="Icon ionic-ios-options"
        transform="translate(-3.375 -5.625)"
      >
        <path
          id="Path_220"
          data-name="Path 220"
          d="M21.045,26.438a2.817,2.817,0,0,1,5.161,0H31.5a1.128,1.128,0,0,1,1.125,1.125h0A1.128,1.128,0,0,1,31.5,28.688H26.205a2.817,2.817,0,0,1-5.161,0H4.5a1.128,1.128,0,0,1-1.125-1.125h0A1.128,1.128,0,0,1,4.5,26.438Z"
          fill="#404040"
        />
        <path
          id="Path_221"
          data-name="Path 221"
          d="M9.795,16.875a2.817,2.817,0,0,1,5.161,0H31.5A1.128,1.128,0,0,1,32.625,18h0A1.128,1.128,0,0,1,31.5,19.125H14.955a2.817,2.817,0,0,1-5.161,0H4.5A1.128,1.128,0,0,1,3.375,18h0A1.128,1.128,0,0,1,4.5,16.875Z"
          fill="#404040"
        />
        <path
          id="Path_222"
          data-name="Path 222"
          d="M21.045,7.313a2.817,2.817,0,0,1,5.161,0H31.5a1.128,1.128,0,0,1,1.125,1.125h0A1.128,1.128,0,0,1,31.5,9.563H26.205a2.817,2.817,0,0,1-5.161,0H4.5A1.128,1.128,0,0,1,3.375,8.438h0A1.128,1.128,0,0,1,4.5,7.313Z"
          fill="#404040"
        />
      </g>
    </svg>
  </div>
</template>