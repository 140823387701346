import axios, {$axios, catch_handler, then_handler} from "../axios";
import programDetails from "../../views/public/course/program-details";

export default {
    namespaced: true,
    state: {
        divisionsLoaded: false,
        divisions: [],
    },
    mutations: {
        updateDivision(state, divisions){
            state.divisionsLoaded = true;
            state.divisions = divisions;
        }
    },
    actions: {
        async loadDivisionsIfNotLoaded({state, commit, dispatch}){
            return new Promise(function ( resolve, reject ){
                if( !state.divisionsLoaded ) {
                    axios( ).get( '/divisions', { params: { districts:'true', areas: 'true' } } )
                        .then( function ({data}) {
                            resolve( data.divisions );
                            commit( 'updateDivision', data.divisions );
                        }).catch(  function ({response}){
                        reject( response );
                        commit( 'updateDivision', [] )
                        setTimeout(() => { dispatch('loadDivisionsIfNotLoaded') }, 4000);
                    });

                } else {
                    resolve( state.divisions );
                }
            })
        },
    },
    getters: {},
    setters:{}
}