export default {
  student: require('./student').default,
  public: require('./public').default,
  batch: require('./batch').default,
  exam: require('./exam').default,
  payment: require('./payment').default,
  products: require('./products').default,
  orders: require('./orders').default,
  reviews: require('./reviews').default,
  pages: require('./pages').default,
  notices: require('./notices').default,
  teachers: require('./teachers').default,
  order: require('./order').default,
  program: require('./program').default,
  participant: require('./participant').default,
  area: require('./areas').default,
  blogs: require('./blogs').default,
  faqs: require('./faqs').default,
  data_collections: require('./data-collection').default,
  saqs: require('./saqs').default,
}