export  default ( ) => {
    let duration = 0;
    function reset( el, duration ){
        delete el.style.display;
        el.classList.remove( 'transition' )
        el.classList.remove( 'ease-linear' )
        el.classList.add( 'opacity-0' )
    }

    function makeOpacity100( el ){

        setTimeout(() => {
            if( duration ){
                el.style.transitionDelay = 0;
                el.style.transitionDuration = `${duration}ms`;
            }
            el.classList.add( 'transition' )
            el.classList.remove( 'opacity-0' )
            el.classList.add( 'opacity-100' )
            el.classList.add( 'ease-linear' )
        },3)
    }

    return  {
        mounted( el, binding, vnode, prevVnode) {
            duration =  vnode.props  ? vnode.props['transition-duration']:0;
            reset( el );
            if( binding.value ) {
                makeOpacity100( el )
            }
        },
        updated( el, binding, vnode, prevVnode ) {
            duration = vnode.props ? vnode.props['transition-duration']:0;
            reset( el )
            if( binding.value ) {
                makeOpacity100( el )
            }
        }
    }

}