<template>
  <component
      :is="rootComponent"
      class="p-4 border rounded-sm shadow flex flex-col justify-between"
      :title="`${item.branch_name} (${item.service_point_name})`"
      v-bind="rootProps"
  >
    <div class="bg-gray-300 w-full shadow-2xl text-center text-xl text-gray-600 font-semibold overflow-hidden">
      <!-- <img class="w-full h-auto" src="../../../../assets/batch.jpg" :alt="item.batchName" /> -->
<!--      <div class="h-56 block mx-auto" >R RR T</div>-->
      <img class="h-48 block mx-auto" v-bind:src="item.image" style="display: block !important; max-width: unset;" :alt="item.display_name" @load="image_loaded"/>
    </div>

    <div class="py-4 flex-grow text-center">
      <h3 class="font-bold text-gray-700 text-base text-center">{{ item.display_name }}</h3>
      <h4 class="font-semibold text-gray-600 text-sm mt-2 text-center">Course : {{ item.course_name }} {{ item.batch_session }} </h4>
      <h5 class="font-semibold text-gray-500 text-sm  text-center">Branch : {{ item.branch_name }} ({{ item.service_point_name }})</h5>
    </div>

    <div class="flex justify-between items-center">


      <template v-if="isLoggedIn() && item.is_admitted">

        <payment-link :participant="item" class="w-full mr-3" v-if="item.due > 0"/>

        <btn variant="success" :router-path="`/my/courses/${item.program_id}/exams`" class="py-2 w-full" >
          Exams
        </btn>

      </template>

      <template v-else>

        <div class="px-4 py-1.5 border rounded text-base font-semibold" :class="{'bg-gray-200': item.price > 0, 'bg-green-200': item.price == 0 }">
          <money-amount v-if="discounted_price > 0" class="text-blue-700" :amount-props="{ class: 'text-lg' }" :amount="discounted_price"/>
          <div v-else class="text-green-600 text-lg">Free</div>
        </div>

        <span class="line-through" v-if="actual_discount > 0">
          <money-amount class="text-blue-700" :amount-props="{ class: 'text-lg' }" :amount="actual_price"/>
        </span>

<!--        <div>{{item.appliedDiscount}}</div>-->

        <btn
              class="px-5 font-semibold"
              :variant="this.enroll ? 'success' : 'warning' "
              size="md"
              v-bind="detailsLinkProps"
              v-on="detailsLinkEvents">{{ enroll? 'Enroll' : 'Details' }}
        </btn>

      </template>

    </div>

  </component>
</template>

<script>

import { setStudentLoginIntent } from "../../../../store/local-storage";
import Btn from "../../../../components/btn";
import {isLoggedIn} from "../../../../store/axios";
import PaymentLink from "../../../student/courses/components/payment-link";
import MoneyAmount from "../../../../components/money-amount";

export default {
  name: "batch-item",
  components: {MoneyAmount, PaymentLink, Btn},
  props: {
    item: { type: Object, default: {} } ,
    enroll: { type: Boolean, default: false }
  },
  created() {
    this.programItem = this.item;

    // this.axios.get( this.item.image ).then(i => {
    //   console.log({i});
    // }).catch((r) => console.dir({r}) )

  },
  updated( ) {
    this.programItem = this.item;
  },
  methods: {
    image_loaded(e){
      console.log({target: e.target} )
    },
    preparingForEnrollment( ){
      this.authenticatedArea((next) => {

        //alert(`/courses/${this.item.programId}/${ this.courseNameSlug }/${this.batchNameSlug}/enrollment`);

        this.$router.push(`/courses/${this.item.program_id}/${ this.courseNameSlug }/${this.batchNameSlug}/enrollment`);
      })
    },
    downloadFile(program_id) {
          this.loading = true;
            this.formErrors = [];

            this.$store.dispatch('batch/downloadInvoice', {
                data: {
                    program_id
                },
            })
            .finally(() => this.loading = false);
    }
  },
  computed: {
    discounted_price(){
      return Math.max(this.item.price - this.item.discount, 0)
    },
    actual_discount(){
      return Math.min(this.actual_price, this.item.discount)
    },
    actual_price(){
      return this.item.price
    },
    rootComponent (){

      if( this.detailsLinkProps.href )
        return 'a'

      if( this.rootProps.to )
        return 'router-link'


      return 'div';
    },
    rootProps(){
      if( this.detailsLinkProps.href ){
        return {href: this.detailsLinkProps.href }
      }

      if( !this.enroll ) {
        return {to: this.detailsLinkProps.routerPath }
      }


      return {};
    },
    detailsLinkEvents(){
      const events = {};
      if( this.enroll ) {
       // events.click = this.enrollToProgram;
        events.click = this.preparingForEnrollment;
      }
      return events;
    },
    detailsLinkProps(){
      const props = { };

      this.afterEnroll = () => {
        this.$router.push( '/my/courses/' + this.item.program_id + '/schedules' );
      }

      if( !this.enroll ) {

        if( this.item.branch_is_own ) {
          props.routerPath = this.detailsLink;
        } else {
          props.href = `${this.item.branch_base_url}${this.detailsLink}`;
        }

      }

      return props;

    },
    detailsLink(){
      return `/courses/${this.item.program_id}/${ this.courseNameSlug }/${this.batchNameSlug}`;
    },
    batchNameSlug(){
      return String( this.item.display_name )
        .replace(/( |_)/ig,'-')
        .toLowerCase();
    },
    courseNameSlug() {
      return String(this.item.course_name)
          .replace(/( |_)/ig, '-')
          .toLowerCase();
    },
    batchName() {
      return this.item.display_name;
    },
    branchName() {
      const service_point = this.item.service_point_name || {};
      const branch = service_point.branch || {};
      return branch.name;
    },
  },
};
</script>

<style scoped>

.shadow-2xl {
  --tw-shadow: inset 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(166, 17, 17, 1)), var(--tw-ring-shadow, 0 0 rgba(121, 17, 17, 1)), var(--tw-shadow);
}
</style>