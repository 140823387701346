<template>
  <div class="fixed w-full z-50 left-0 right-0 flex justify-center msg" :class="top" >
    <div class="w-3/12 text-center">
      <status-message background v-bind="options">{{message}}</status-message>
    </div>
  </div>
</template>

<script>
import statusMessage from "./status-message";
export default {
  name: "flash-message",
  components: {statusMessage},
  data(){
    return {
      top: '-hide-to-top',
      message: '',
      options: {},
      timer: 0
    }
  },
  methods: {
    slideDown( ){
      this.top = '-hide-to-top';
    },
    slideUp({ message, options } ){
      clearTimeout( this.timer );

      this.message = message;
      this.options = options;
      this.top = 'top-4';

      this.timer = setTimeout( this.slideDown, 5000 );
    }
  },
  created() {
    this.$emit( 'setup', { flash: this } );
  }
}
</script>

<style scoped>

  .msg {
    transition: 0.8s;
  }

  .-hide-to-top {
    top: -105%;
  }

</style>