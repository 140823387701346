import {setModel} from "./base";

function matcher( data,  matchData, keys = [] ){
    return keys.map(key => data[key] == matchData[key] ).indexOf( false ) === -1;
}

export default setModel(function( item, index  ) {

    return  {
        displayName: item.displayName || '',
        price: item.price || '',
        totalPrice: item.totalPrice || '',
        quantity: item.quantity || 1,
        discount: item.discount || '',
        isPackage: item.isPackage || null,
        productId: item.productId || null,
        packageId: item.packageId || null,
        isProgramProduct: item.isProgramProduct || false,
        isFree: item.isFree || false,
        programDiscount: item.packageDiscount || '',
        packageDiscount: item.packageDiscount || '',
        typeText: item.typeText || '',
        packageProducts: item.packageProducts || [],

        orderItemExists : item.orderItemExists || false,
        orderItemId : item.orderItemId || null,

        isDeleted : false,
        setDeleted : ( deleted ) => {
            item.isDeleted = deleted;
        },
        inProgramProducts : ( ) => {
            return item.isProgramProduct;
        },
        matches : ( values ) => {
            values = Object.keys( values ).map( key => item[key] === values[key] );
            return values.indexOf( false ) === -1;
        },
        getIndex : () => {
            return index;
        },
    };

});


//create or update on check
/*
appliedDiscount: 0
displayName: "Math lecture"
inMarketplace: true
isFree: false
isPackage: false
isProgramProduct: true
orderItemExists: false
orderItemId: 0
packageDiscount: 0
packageId: null
packageProductId: null
packageProducts: []
price: 630
productId: 2
productName: "Math lecture"
programDiscount: 0
programId: 2
programProductId: 2
quantity: 1
table: "program_products"
totalPrice: 630
typeText: "Lecture Sheet"
updatedAt: null
* */

//create or update on product add btn

/*
* appliedDiscount: 0
displayName: "2012 lecture sheet"
inMarketplace: true
isFree: false
isPackage: false
isProgramProduct: false
orderItemExists: true
orderItemId: 18
packageDiscount: 0
packageId: null
packageProductId: null
packageProducts: []
price: 420
productId: 3
productName: "2012 lecture sheet"
programDiscount: 0
programId: null
programProductId: null
quantity: 1
table: "market_places"
totalPrice: 420
typeText: "Lecture Sheet"
updatedAt: "2022-01-08T05:11:44.000000Z"
* */


// Remove on check

/*
* appliedDiscount: 0
displayName: "BCS Maths"
inMarketplace: true
isFree: false
isPackage: false
isProgramProduct: true
orderItemExists: true
orderItemId: 19
packageDiscount: 0
packageId: null
packageProductId: null
packageProducts: []
price: 650
productId: 1
productName: "BCS Maths"
programDiscount: 0
programId: 2
programProductId: 1
quantity: 1
table: "program_products"
totalPrice: 650
typeText: "Book"
updatedAt: null
* */

//remove on click

/*
appliedDiscount: 0
displayName: "BCS Maths sheet"
getIndex: () => { return index; }
inProgramProducts: ( ) => { return item.isProgramProduct; }
isDeleted: false
isPackage: false
isProgramProduct: false
matches: ( values ) => {…}
orderId: 1
orderItemId: 17
orderPackageItems: []
packageId: null
price: 1020
productId: 7
quantity: 1
setDeleted: ( deleted ) => { item.isDeleted = deleted; }
totalPrice: 1020
*/
