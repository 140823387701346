import axios, {$axios, catch_handler, then_handler} from "../axios";

export default {
    namespaced: true,
    state: {
        teachers: {}
    },
    mutations: {
        setTeacher(state, teachers) {
            state.teachers = teachers
        }
    },
    actions: {
        async teacherData({state, commit}, params = {}) {
            params = params || {};

            return await axios().get('/teachers', {params: params.params})
                .then((response) => {
                    commit('setTeacher', response.data.teachers)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    getters: {
        teachers(state) {
            return state.teachers
        }
    },
    modules: {

    }
}
