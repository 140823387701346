<template>
  <container class="text-center py-10 bg-white shadow-md my-4" page-title="@heading" >
    <template #heading>
      <span class="text-blue-700 font-bold" >
        404 Not Found
      </span>
    </template>

    <div class="text-3xl text-orange-500 mt-2">
      <div class="w-full flex justify-center mb-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="213" height="213" viewBox="0 0 213 213" class="text-orange-500 hover:text-orange-400">
          <g id="Group_2999" data-name="Group 2999" transform="translate(-14159 1899)">
            <g id="Ellipse_38" data-name="Ellipse 38" transform="translate(14159 -1899)" fill="rgba(255,255,255,0)" stroke="currentColor" stroke-width="11">
              <circle cx="106.5" cy="106.5" r="106.5" stroke="none"/>
              <circle cx="106.5" cy="106.5" r="101" fill="none"/>
            </g>
            <g id="Group_3000" data-name="Group 3000">
              <g id="Ellipse_39" data-name="Ellipse 39" transform="translate(14198 -1847)" fill="rgba(255,255,255,0)" stroke="currentColor" stroke-width="7">
                <circle cx="19" cy="19" r="19" stroke="none"/>
                <circle cx="19" cy="19" r="15.5" fill="none"/>
              </g>
              <g id="Ellipse_40" data-name="Ellipse 40" transform="translate(14209 -1827)" fill="rgba(255,255,255,0)" stroke="currentColor" stroke-width="6">
                <circle cx="8" cy="8" r="8" stroke="none"/>
                <circle cx="8" cy="8" r="5" fill="none"/>
              </g>
            </g>
            <g id="Group_3001" data-name="Group 3001" transform="translate(95)">
              <g id="Ellipse_39-2" data-name="Ellipse 39" transform="translate(14198 -1847)" fill="rgba(255,255,255,0)" stroke="currentColor" stroke-width="7">
                <circle cx="19" cy="19" r="19" stroke="none"/>
                <circle cx="19" cy="19" r="15.5" fill="none"/>
              </g>
              <g id="Ellipse_40-2" data-name="Ellipse 40" transform="translate(14209 -1827)" fill="rgba(255,255,255,0)" stroke="currentColor" stroke-width="6">
                <circle cx="8" cy="8" r="8" stroke="none"/>
                <circle cx="8" cy="8" r="5" fill="none"/>
              </g>
            </g>
            <path id="Path_424" data-name="Path 424" d="M14274-1596.21s31.335-56.4,77.73,0" transform="translate(-47.365 -137.544)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="10"/>
          </g>
        </svg>


      </div>
      <div class="font-semibold">
        Sorry, Your requested page not found
      </div>
    </div>
    <div class="w-full justify-center mt-16">
      <btn router-path="/" variant="success" class="mr-5" size="md">Home</btn>
      <btn router-path="/courses" variant="info"  size="md">Courses</btn>
    </div>

  </container>
</template>

<script>
import Container from "../layouts/container";
import Btn from "../../components/btn";
export default {
  name: "NotFound",
  components: {Btn, Container}
}
</script>

<style scoped>

</style>