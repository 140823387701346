import axios, {$axios, catch_handler, then_handler} from "../axios";
import cart from "../../models/cart";
export default {
    namespaced: true,
    state: {
        programParticipant: null,
        allParticipation: [],
        listLoading: false
    },
    mutations: {
        setAllParticipation( state, data ){
            state.allParticipation = data;
        },
        setProgramParticipant( state, participant ){
            state.programParticipant = participant;
        }
    },
    actions: {
        async loadAllParticipation({ state }, data ){
            return await axios().get( `/student/programs`, {params: (data || {}).params } )
                .then( then_handler( data, ( result ) => state.allParticipation = ( result.data || { courses: null }).courses ))
                .catch( catch_handler( data ) )
                .finally( () => state.listLoading = false )
        },
        async single({ state, commit }, data ){
            const programId = data.programId;
            const participantId = data.participantId;
            const loadFromServer = typeof data.loadFromServer == 'boolean' ? data.loadFromServer:(( data.params || { }).with);

            if( Array.isArray( state.allParticipation ) && loadFromServer === false ) {
                const filtered = state.allParticipation.filter( p => p.id == participantId );
                if( filtered[0] ) {
                    return Promise.resolve({
                        participant: filtered[0],
                        loadedFromServer: false,
                        loadFromServer
                    });
                }
            }

            return new Promise((resolve,reject) => {
                axios( ).get( `/student/programs/${programId}` , { params: data.params } )
                    .then( then_handler( data, ( result ) => {


                            const participant = (result.data || {}).participant || {};
                            const order = (result.data || {}).order || {};
                            const payment_token = (result.data || {}).payment_token || '' ;

                            commit( 'setProgramParticipant', participant );
                            commit( 'order/setOrder', {order, participant}, { root: true });

                            return resolve({
                                participant, order, payment_token,
                                loadedFromServer: true,
                                loadFromServer,
                                error: null
                            });
                        }
                    ))
                    .catch(catch_handler( data, ( err, result ) =>
                        reject({
                            participant: null,
                            loadedFromServer: true,
                            loadFromServer,
                            message: err,
                            error: [result]
                        })
                    ));
            })

        }
    },
    getters: {},
    modules: {}
}