<template>
  <div>
    <nav>
      <div
        :class="$store.state.header.class"
        class="
          bg-white
          w-full
          max-w-6xl
          mx-auto
          flex flex-col
          md:flex-row
          justify-between
          items-center

        "
        v-click-away="outsideNavClick"
      >
        <div class="w-full flex justify-between items-center">
          <router-link to="/">
            <full-logo class="h-6 w-auto" />
          </router-link>
          <div class="flex md:hidden" v-if="!isLoggedIn()">
            <join-button />
          </div>
          <div class="md:hidden flex">
            <button
              class="border-0 focus:outline-none p-2"
              onclick="
                document.getElementById('navigationContainer').classList.toggle('flex');
                document.getElementById('navigationContainer').classList.toggle('hidden');
              "
            >
              <menu-icon-svg class="w-6" />
            </button>
          </div>
        </div>
        <div
          class="
            md:flex
            hidden
            flex-col
            md:flex-row
            w-full
            md:w-auto
            mt-4
            md:mt-0
          "
          id="navigationContainer"
        >
          <div
            class="
              w-full
              order-3
              md:order-1
              flex flex-col
              md:flex-row
              justify-center
              md:justify-end
              items-center
            "
          >
            <navigation-menu />
          </div>

          <div class="order-1 md:order-3 min-w-max w-full flex md:w-auto">
            <div class="flex w-full" v-if="isLoggedIn()">
              <student-navigation-menu />
            </div>
            <div class="hidden md:flex" v-if="!isLoggedIn()">
              <join-button />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import FullLogo from "../../components/full-logo.vue";
import MenuIconSvg from "../../components/menu-icon-svg.vue";
import JoinButton from "./join-button.vue";
import NavigationMenu from "./navigation.vue";
import StudentNavigationMenu from "./student-navigation.vue";

export default {
  components: {
    FullLogo,
    MenuIconSvg,
    NavigationMenu,
    JoinButton,
    StudentNavigationMenu,
  },
  methods: {
    outsideNavClick(){
      document.getElementById('navigationContainer').classList.remove('flex');
      document.getElementById('navigationContainer').classList.add('hidden');
    }
  }
};
</script>