<template>
  <div class="absolute">
    Before the program enroll
  </div>
</template>

<script>
export default {
  name: "program-enroll-before"
}
</script>

<style scoped>

</style>