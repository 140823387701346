<template>
  <router-link
    class="
      w-full
      md:w-auto
      min-w-max
      pl-8
      md:px-4
      py-1
      mx-1
      rounded-sm
      font-semibold
      text-gray-500
    "
    exact-active-class="md:bg-blue-600 bg-white text-blue-500 md:text-white"
    :to="to"
    onclick="document.getElementById('studentNavigationContainer').classList.add('md:hidden')"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: String,
  },
};
</script>