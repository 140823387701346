import  { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './css/font.css';
import './css/main.css';
import 'vue-advanced-cropper/dist/style.css';
import VueAxios from 'vue-axios'
import axios from "axios";
import globalMixin from "./mixins/global-mixin";
import VueClickAway from "vue3-click-away";
import {fadeIn} from './directives'
import './assets/tailwind.css'
import Vue3Marquee from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import Btn from './components/btn.vue';
import helpers from "./core/helpers";
import { Cropper } from 'vue-advanced-cropper'

const app = createApp(App);

app.mixin( globalMixin );
app.use(store);
app.component('btn',Btn)
app.component( Cropper )
app.use(VueClickAway);
app.use( VueAxios, axios );
app.use(router);
app.use(Vue3Marquee);

app.use(helpers);



app.directive('fade-in', fadeIn() );

app.mount('#app');

