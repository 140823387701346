<template>
  <div class="w-full min-h-screen flex flex-col justify-between">
    <flash-message @setup="flashMessage"/>

    <div>
      <!-- header -->
      <div class="w-full bg-white shadow-sm border-b z-50 sticky top-0" style="z-index: 8000" v-show="!$store.state.header.isHidden">
        <layout-header />
      </div>

      <!-- main -->
      <main class="px-4">
        <router-view />
      </main>
    </div>

    <!-- footer -->
    <footer>
      <div class="w-full">
        <layout-footer />
      </div>
    </footer>

  </div>
</template>

<script>
import layoutHeader from "./header.vue";
import layoutFooter from "./footer.vue";
import Overly from "../../components/overly";
import Spinner from "../../components/spinner";
import FlashMessage from "../../components/flash-message";

export default {
  components: {FlashMessage, Spinner, Overly, layoutHeader, layoutFooter },
  name: "PublicLayout",
  updated() {
    window.scrollTo({ top: 0 });
  },
  data(){
    return {
      headerChangeDelay: 80,
      headerChangeTimer: 0,
    }
  },
  mounted() {
    document.addEventListener('scroll',  ( event ) => {
      if( event.isTrusted ) {
        this.setScrollTop();
      }
    });
    this.setScrollTop();
  },
  methods:{
    setScrollTop( ){
      const scrollTop = (document.documentElement || document.body.parentNode || document.body || {scrollTop: 0}).scrollTop;
      this.$store.commit('setScrollTop', scrollTop );
    },
    flashMessage({ flash }){
      this.$store.commit( 'setFlashMessage', flash );
    },
    headerChange( top ){
      if( top > 60 && String(this.$store.state.header.class).search(/(py\-px)/) === -1 ) {
        this.$store.commit('setHeaderData', {
          header_class: 'py-px px-2'
        });
      } else if( top <= 60 &&  String(this.$store.state.header.class).search(/(py\-px)/) > -1 ) {
        this.$store.commit('setHeaderData', {
          header_class: 'p-2'
        });
      }

      clearTimeout(this.headerChangeTimer);
    }
  },
  watch: {
    '$store.state.scrollTop'( top ){
      this.headerChangeTimer = setTimeout( this.headerChange, this.headerChangeDelay, top )
    }
  }

};
</script>