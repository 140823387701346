import profileTabs from  "../views/student/profile/routes";

export  default [
    {
        path: 'dashboard',
        name: 'student-dashboard',
        component: () => import( "../views/student/dashboard" ),
        meta: {
            title: "Dashboard | ${name}"
        }
    },
    {
        path: 'profile',
        name: 'student-profile',
        component: () => import( "../views/student/profile" ),
        children: profileTabs
    },
    {
        path: 'courses',
        name: 'student-courses',
        component: () => import( "../views/student/courses" ),
        meta: {
            title: "My Courses | ${name}"
        }
    },

    {
        path: 'courses/:programId/schedules',
        name: 'CourseSchedules',
        component: () => import( "../views/student/courses/components/schedules" ),
        meta: {
            title: "My Courses | ${name}"
        }
    },

    {
        path: 'courses/:programId/lecture-details/:lectureId',
        name: 'student-courses-content-details',
        component: () => import( "../views/student/courses/components/LectureDetails.vue" ),
        meta: {
            title: "Course Material | ${name}"
        }
    },


    {
        path: 'courses/:program_id/exams',
        name: 'student-courses-exams',
        component: () => import( "../views/student/exams" ),
        meta: {
            title: "My Exams | ${name}"
        }
    },
    // {
    //     path: 'courses/:program_id/schedules',
    //     name: 'student-courses-schedules',
    //     component: () => import( "../views/student/schedule" ),
    //     meta: {
    //         title: "My Schedules | ${name}"
    //     }
    // },
    {
        path: 'course/:program_id/content/:content_id/:subject/:type/:serial',
        name: 'student-course-content-view',
        component: () => import( "../views/student/content/view" ),
        meta: {
            title: "Course content | ${name}"
        },
        children: [
            {
                path: ':material_id/material',
                name: 'student-courses-content-material-view',
                component: () => import( "../views/student/content/view-materials" ),
                meta: {
                    title: "Course Material | ${name}"
                }
            }
        ]
    },
    {
        path: 'payment/:paymentableType/:paymentableId',
        name: 'student-payment',
        component: () => import( "../views/student/payments" ),
        meta: {
            title: "Payment | ${name}"
        }
    },
    {
        path: 'exams/:id?',
        name: 'student-exams',
        component: () => import( "../views/student/exams" )
    },
    {
        path: 'exams/:examId/participate/:participantExamId',
        name: 'student-exams-participate',
        component: () => import( "../views/student/exams/exam-participation" )
    },
    {
        path: 'exams/:examId/result/:participantId',
        name: 'student-exams-result',
        component: () => import( "../views/student/exams/exam-result" ),
        children:[
            {
                path:'answers',
                name: 'student-exams-result-answers',
                component: () => import( "../views/student/exams/exam-answers" ),
            }
        ]
    },
    {
        path: 'review/create',
        name: 'student-review-create',
        component: () => import( "../views/student/review" ),
        meta: {
            title: "Student review Create | ${name}"
        }
    },
    {
        path: 'blog',
        name: 'blog',
        component: () => import( "../views/student/blog/index.vue" ),
        meta: {
            title: "Blog"
        }
    },
    {
        path: 'create-blog',
        name: 'create-blog',
        component: () => import( "../views/student/blog/components/create-blog" ),
        meta: {
            title: "Blog"
        }
    },
    {
        path: 'blog/:id',
        name: 'single-post',
        component: () => import( "../views/student/blog/components/post-item.vue" ),
        meta: {
            title: "Blog"
        }
    },
    {
        path: 'blog/profile',
        name: 'profile',
        component: () => import( "../views/student/blog/components/blog-profile.vue" ),
        meta: {
            title: "Blog"
        }
    },
    {
        path: 'payment/process',
        name: 'test',
        component: () => import("../views/components/payments/payment-process.vue"),
        meta: {
            title: 'Choose Payment Process'
        }
    },

]