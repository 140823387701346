import axios from "axios";

const _base_url = process.env.NODE_ENV === 'development' ? LOCAL_BASE_URL: BASE_URL;
const _app_key = process.env.NODE_ENV === 'development'
                    && typeof APP_KEY !== 'undefined' ? APP_KEY: false;

const url_prefix = API_URL_PREFIX || 'api';
const base_url = ( _base_url ) + url_prefix;
const csrf_url = ( _base_url ) + 'sanctum/csrf-cookie';

const LOGIN_TOKEN_KEY = 'QyUhbYDudiSudcyqTrzPYgwaQeac';
const LOGIN_TOKEN_HASH_KEY = 'sUydjeeyutu2qtutusy';



const set_token = (token, tokenHash) => {
    if (token === false) {
        localStorage.removeItem(LOGIN_TOKEN_KEY);
        localStorage.removeItem(LOGIN_TOKEN_HASH_KEY);
    } else {
        localStorage.setItem(LOGIN_TOKEN_HASH_KEY, tokenHash);
        return localStorage.setItem(LOGIN_TOKEN_KEY, token);
    }
}

const isLoggedIn = () => {
    const token = localStorage.getItem(LOGIN_TOKEN_KEY);
    return token !== null;
}

const get_token = () => {
    return localStorage.getItem(LOGIN_TOKEN_KEY);
}
const get_token_hash = () => {
    return localStorage.getItem( LOGIN_TOKEN_HASH_KEY );
}

function getHeaders() {

    const headers = {
        ContentType: 'Application/json',
        Accept: 'Application/json',
    }

    if(_app_key) {
        headers['X-APP-KEY'] = _app_key;
    }

    const token = get_token();

    //alert( token );

    if (token) {
        headers.Authorization = "Bearer " + token;
    }

    return headers;
}

async function set_csrf_cookie() {
    return await axios.get(csrf_url, { withCredentials: true });
}



function call_has_errors( params, errors ){
    if (typeof params.has_errors == 'function') {
        params.has_errors( errors );
    }
}

function call_response_callback( params, response){
    if( typeof params.response == 'function'  ) {
        params.response( response );
    }
}

const then_handler = (params, callback) => {


    return function (response) {
        call_response_callback( params || {}, response );

        call_has_errors(params || {}, []);

        if (typeof (params || {}).success == "function") {
            params.success(response);
        }

        if (typeof callback == "function") {
            callback(response);
        }
    }
}

const catch_handler = (params, callback) => {


    return function ( result ) {

        const response = (result || {}).response;


        call_has_errors(params || {}, []);

        if (typeof (params || {}).error == 'function') {

            params.error( response, response ? response.status:null, response ? (response.data ? response.data.message:''):'');
        }

        call_response_callback( params || {}, response );

        if (response) {

            if ( response.status == 401 ) {
                set_token(false);
            }
            //console.log( response, 'DDDDDDDDDDD')

            if (response.status == 422) {
                if (typeof (params || {}).has_errors == 'function') {
                    call_has_errors(params || {}, response.data.errors);
                }
            }
        }

        if (typeof callback == "function") {
            callback(response, result);
        }
    }
}


let csrfCookieLoaded = false;

function setCsrfCookieLoaded(condition = true) {
    csrfCookieLoaded = condition;
}

async function axios_create(options = { timeout: 1000000, csrf: true }) {
    return axios.create({
        baseURL: base_url,
        timeout: options.timeout,
        headers: getHeaders(),
        withCredentials: true
    });
}


async function csrf() {

    if (!csrfCookieLoaded) {
        await set_csrf_cookie()
            .catch(() => {
                setTimeout(() => {
                    return csrf()
                }, 5000)
            });
    }

    return await axios_create();
}


const main = (options = { timeout: 1000000, csrf: true }) => {

    if (!csrfCookieLoaded) {
        const csrf = set_csrf_cookie();
        csrfCookieLoaded = true;
    }

    return  axios.create({
        baseURL: base_url,
        timeout: options.timeout,
        headers: getHeaders(),
        withCredentials: true
    });

};

export default main;

const $axios = {
    get : async ( url, params = {} ) =>{
        return await main( ).get( url, { params: params.params } )
            .then( then_handler ( params ) )
            .catch( catch_handler ( params ) );
    },
    post : async (url, params = {} ) => {
        return await  main().post(url, params.data, { params: params.params } )
            .then( then_handler ( params ) )
            .catch( catch_handler ( params ) );
    }
}

export { base_url, $axios, get_token, set_token, set_csrf_cookie, get_token_hash, then_handler, getHeaders, catch_handler, isLoggedIn, setCsrfCookieLoaded }
