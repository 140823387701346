<template>
  <container v-if="$route.params.programId" :loading="loading" id="course-details">
<!--    <template #heading>Course Details</template>-->
    <div style="min-height: 300px" class=" mt-8 mb-10">


    <div class="grid md:grid-cols-3">

      <div class="col-span-1">
        <batch-item :item="batchItem" :enroll="true" style=""></batch-item>
      </div>

      <div class="md:col-span-2 overflow-hidden md:ml-6">

        <div :style="`max-height:  ${detailsMaxHeight}`" class="overflow-hidden">
          <h2 class="text-xl font-bold mb-4">{{batchItem.display_name}}</h2>
          <div>Course: {{batchItem.course_name}}</div>
          <div>Session: {{batchItem.batch_session}}</div>
          <p v-html="batchItem.details" class="ck-content text-justify" style="  overflow: hidden"></p>
        </div>

        <div class="mt-6 w-full flex justify-between">

          <btn variant="success-outline" class="mt-6" :router-path="`/courses/${$route.params.programId}/schedules`" v-if="batchItem.hasSchedule">
            View Schedule
          </btn>

          <btn variant="success-outline" class="mt-6" :router-path="`/my/courses/${$route.params.programId}/exams`" v-else>
            Exams
          </btn>

          <btn variant="primary-outline" class="mt-6" @click="detailsMaxHeight =  detailsMaxHeight === 'auto' ? '350px':'auto' ">
            {{ detailsMaxHeight === 'auto' ? 'Show Less':'Show More' }}
          </btn>
          <btn variant="info-outline" class="mt-6" :router-path="`/courses/${$route.params.programId}/${$route.params.courseName}/${$route.params.batchName}/course-contents`" >
            Course Contents
          </btn>
        </div>

      </div>

    </div>

  </div>
  </container>
</template>

<script>
import Overly from "../../../components/overly";
import Spinner from "../../../components/spinner";
import BatchItem from "../home/components/batch-item";
import Container from "../../layouts/container";
import Btn from "../../../components/btn";
import ProgramEnrollBefore from "../../student/courses/components/program-enroll-before";
export default {
  name: "program-details",
  components: {ProgramEnrollBefore, Btn, Container, BatchItem, Spinner, Overly},
  data() {
    return {
      loading: true,
      batchItem: {},
      detailsMaxHeight: '350px'
    }
  },
  methods:{
    loadProgram() {
      this.loading = true;
      this.$store
          .dispatch( 'batch/programDetails', {
            params: { program_id: this.$route.params.programId },
          })
          .finally(() => {
            this.loading = false;
            this.$emit("loaded", this.batchItem);
            this.scrollTop();
          });
    },
  },
  created() {
    this.loadProgram();
  },
  watch: {
    '$route.params.programId'( ){
      this.loadProgram( );
    },
    '$store.state.batch.programDetails'( batchDetails ){
      this.batchItem = batchDetails;
    }
  }

};
</script>

<style scoped>
</style>
