<template >

  <div class="relative w-full mx-auto my-3" :class="getWidth" >
    <h1 class="text-4xl text-center" :class="headingCLass"><slot name="heading"></slot></h1>

    <div class="w-full my-3" v-bind="bodyProps">
      <div class="my-3" :style="`min-height: ${minHeight}`">
        <overly class="rounded" background="bg-blue-100" v-if="loading">
          <spinner class="text-red-600 h-10 w-10"></spinner> {{textOnLoading}}
        </overly>
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
import Spinner from "../../components/spinner";
import Overly from "../../components/overly";
export default {
  components: {Overly, Spinner},
  props: {
    minHeight: { type: String, default: '400px' },
    headingCLass: { type: String , default:''},
    pageTitle: { type: String, default:null },
    maxW: { type: String, default: '6xl' },
    loading: { type: Boolean, default: false },
    bodyProps: { type: Object, default: { } },
    textOnLoading: { type: String, default: "Loading..." },
  },
  name: "container",
  computed: {
    getWidth(){
      switch ( this.maxW ) {
        default :
          return 'max-w-'+ this.maxW;
      }
    }
  },
  created() {
      if( this.pageTitle == '@heading' ) {
        if( Array.isArray( this.$slots.heading() ) && this.$slots.heading()[0] ) {
          document.title = this.$slots.heading()[0].children ;
        }
      } else if( this.pageTitle !== null ) {
        document.title = this.pageTitle ;
      }
  },
}
</script>

<style scoped>

</style>