import {createRouter, createWebHistory} from 'vue-router';
import adminRoutes from "./admin-routes";
import studentRoutes from "./student-routes";
import publicRoutes from "./public-routes";
import {isLoggedIn, set_token} from "../store/axios";
import {setIntent, setStudentLoginIntent} from "../store/local-storage";
import Layout from "../views/layouts/App";
import {authenticateBeforeNext} from "./functions";


const routes = [

  {

    path: '/',
    name: 'public',
    component: Layout,
    children: publicRoutes,
    beforeEnter: ( to, from, next ) => {
      setIntent( to.fullPath );
      next();
    },

  },
  {
    path: '/student/set-token/:token/:tokenHash?',
    name: 'student-set-token',
    beforeEnter: ( to, from, next ) => {
      if ( ! isLoggedIn( ) ) {
        set_token( to.params.token, to.params.tokenHash );
      }
    }
  },
  {
    path: '/my',
    name: 'student',
    component: Layout,
    children: studentRoutes,
    beforeEnter: authenticateBeforeNext
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import( "../views/admin/layouts/App" ),
    children: adminRoutes,
    beforeEnter: (to, from, next) => {
      setIntent( to.fullPath );

      if ( false ){


        //next({ path: '/admin/login' })
      }
      else next()
    }
  },

]

const router = createRouter({
  history: createWebHistory( window.BASE || '/' ),
  routes,
})

export default router;

