export default [
    {
        path: '',
        name: 'student-profile-basic-data',
        component: () => import( "./basic-data" ),
        meta: {
            heading: "Profile Basic",
            tabTitle: "Profile Basic Data",
            title: "Profile | ${name}"
        }
    },
    {
        path: 'change-password',
        name: 'student-profile-change-password',
        component: () => import( "./change-password" ),
        meta: {
            heading: "Change Password",
            tabTitle: "Change Password",
            title: "Change password | ${name}"
        }
    },
    {
        path: 'enrolled-programs',
        name: 'student-profile-enrolled-programs',
        component: () => import( "./programs" ),
        meta: {
            heading: "My Enrolled Courses",
            tabTitle: "My Courses",
            title: "My Enrolled Courses | ${name}"
        }
    }
]