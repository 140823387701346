import axios, {$axios, catch_handler, then_handler} from "../axios";


async function get_page({slug, params}, commit){
    return (await
        axios()
            .get(
                `/pages?slug=${slug}`,
                {
                    params: params.params
                }
            )
    ).data.page
}

/*
function create_page_action(slug){
    return ({state, commit}, params = {}) => {
        commit('setAboutUs', get_page({slug,params}) );
    }
}
function create_page_mutation(stateName){
    return (state, content ) => {
        state[stateName] = content
    }
}
*/


export default {
    namespaced: true,
    state: {
        aboutUs: {},
        contactUs: {},
        termsAndCondition: {},
        pages: {a:'dd'},
    },
    mutations: {

        setAboutUs(state, aboutUs) {
            state.aboutUs = aboutUs
        },
        setContactUs(state, contactUs) {
            state.contactUs = contactUs
        },
        termsAndCondition(state, page) {
            state.termsAndCondition = page;
        }
    },
    actions: {

        async AboutUsPage({state, commit}, params = {}) {
            params = params || { };

            return await axios().get('/pages?slug=about-us', { params: params.params } )
                .then((response) => {
                    commit('setAboutUs', response.data.page);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async ContactUsPage({state, commit}, params = {}) {
            params = params || { };

            return await axios().get('/pages?slug=contact-us', { params: params.params } )
                .then((response) => {
                    commit('setContactUs', response.data.page)
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /*async termsAndCondition({state, commit}, params = {}) {
            params = params || { };

            return await axios().get('/pages?slug=terms-and-condition', { params: params.params } )
                .then((response) => {
                    commit('termsAndCondition', response.data.page)
                })
                .catch((error) => {
                    console.log(error);
                });
        },*/

        async setPage( { state, commit }, slug ) {
            if( !state.pages[slug] ) {
                try {
                    state.pages[slug] = ( await axios().get(`/pages?slug=${slug}` ) ).data.page;
                }catch (err){
                    console.log(err)
                }
            }
        },

    },
    getters: {
        aboutUs (state) {
            return state.aboutUs;
        },
        contactUs (state) {
            return state.contactUs;
        }
    },
    modules: {

    }
}
