
export default [
    {
        path: '',
        name: 'admin-dashboard',
        component: () => import('../views/admin/dashboard')
    },
    {
        path: 'students',
        name: 'admin-studentList',
        component: () => import('../views/admin/students/list')
    },
    {
        path: 'branches',
        name: 'admin-branchList',
        component: () => import('../views/admin/branches/list')
    }
]
