import axios, {$axios, catch_handler, then_handler} from "../axios";

export default {
    namespaced: true,
    state: {
        forms: [],
    },
    mutations: {
        set_forms(state, forms) {
            state.forms = forms
        },
    },
    actions: {
        async get_forms({state, commit}, params = {}){
            params = params || {};
            return new Promise((resolve, reject) => {
                if( state.forms.length > 0 ) {

                    resolve( state.forms );

                } else {
                    axios().get('/data-collection-forms', {params: params.params})

                        .then(({data}) => {
                            if( Array.isArray(data.forms) )  {
                                commit('set_forms', data.forms);
                                resolve(data.forms);
                            }
                        })
                        .catch( ({response}) => {
                            reject(response);
                        });
                }
            });
        },
        async show({state, commit}, params = { }){
            params = params || {  };
            return await axios( ).get(`/data-collection-forms/${params.id}`, {
                params: {...params.params, ...{type: params.type}}
            })
        },
    },
    getters: { },
    modules: { }
}
