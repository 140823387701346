<template>
  <div class="relative w-full" v-click-away="profileDropdownAwayClick">
    <div
      onclick="
        document.getElementById('studentNavigationContainer').classList.toggle('md:hidden');
      "
      class="
        cursor-pointer
        w-full
        flex
        items-center
        md:w-auto
        min-w-max
        px-2
        py-1
        ml-1
        rounded-sm
        font-bold
        text-gray-700
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span>{{ $store.getters["student/name"] }}</span>
      <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5 ml-auto">
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>

    <div
      id="studentNavigationContainer"
      class="
        z-50
        flex
        md:hidden
        md:absolute
        md:w-full
        top-full
        right-0
        flex-col
        md:border
        md:shadow
        min-w-max
        bg-white
        p-2
      "
    >
      <nav-link to="/my/dashboard">Dashboard</nav-link>
      <nav-link to="/my/profile">Profile</nav-link>
      <nav-link to="/my/courses">Courses</nav-link>
      <nav-link to="/my/exams">Exams</nav-link>
      <nav-link to="/notices">Notice</nav-link>
      <hr />
      <button
        class="
          w-full
          text-left
          pl-8
          md:px-4
          py-1
          mx-1
          rounded-sm
          font-semibold
          text-red-500
        "
        @click="
          $store.dispatch( 'student/logout', {
            loggedOut: () => {
              $router.push('/');
            },
          })
        "
      >
        Logout
      </button>
    </div>
  </div>
</template>

<script>
import NavLink from "./student-nav-link.vue";
export default {
  components: { NavLink },
  methods: {
    profileDropdownAwayClick() {
      document
        .getElementById("studentNavigationContainer")
        .classList.add("md:hidden");
    },
  },
};
</script>