<template>
  <span>
    <slot name="prefix"/>
    <span v-bind="symbolProps" v-html="`${currencySymbol}`"/> <span v-bind="amountProps">{{getAmount}}</span>
    <slot name="suffix"/>
  </span>
</template>

<script>
import {numWithThousandSeparator} from "../core/math";

export default {
  name: "money-amount",
  props: {
    symbolProps: { type:Object, default: { } },
    amountProps: { type:Object, default: { } },
    currencySymbol: {
      type: String, default: '&#2547;',
    },
    amount: {
      default: '0',
    }
  },
  computed: {
    getAmount(){
      return numWithThousandSeparator( this.amount )
    }
  }
}
</script>

<style scoped>

</style>