import axios, {$axios, catch_handler, then_handler} from "../axios";

export default {
    namespaced: true,
    state: {
        notices: {},
    },
    mutations: {
        setNotice(state, notices) {
            state.notices = notices
        },
    },
    actions: {
        async noticeData({state, commit}, params = {}){
            params = params || {};

            return await axios().get('/notices', {params: params.params})
                .then((response) => {
                    commit('setNotice', response.data.notices)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    getters: {
        notices(state) {
            return state.notices
        },
    },
    modules: {

    }
}
