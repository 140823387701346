const INTENT_PATH_KEY = '@@uyufshsuyc@@';
const STUDENT_LOGIN_INTENT_PATH_KEY = '@@qyuyfush@@';
const STUDENT_USER_DATA_KEY = '@@yuusyyuctQaaxyzxhyaO@@';

function setUserData( user ){
    if( user === null ) {
        localStorage.removeItem( STUDENT_USER_DATA_KEY );
    }else {
        localStorage.setItem( STUDENT_USER_DATA_KEY, JSON.stringify( user ) );
    }
}

function getUserData( user ){
    const userData = localStorage.getItem( STUDENT_USER_DATA_KEY );
    try {
        return JSON.parse( userData );
    }catch ( err ) {
        return { };
    }
}

function setIntent( path ){
    localStorage.setItem( INTENT_PATH_KEY, path );
}

function getIntent( path ){
    return localStorage.getItem( INTENT_PATH_KEY  );
}

function setStudentLoginIntent( path ){
    localStorage.setItem( STUDENT_LOGIN_INTENT_PATH_KEY, path );
}

function getStudentLoginIntent(){
    return localStorage.getItem( STUDENT_LOGIN_INTENT_PATH_KEY );
}


export { setIntent, getIntent, setStudentLoginIntent, getStudentLoginIntent, getUserData, setUserData };