<template>

  <div  v-if="!$store.state.appDataLoaded"  class="__preloader z-50 fixed inset-0">

    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 154.67 37.42">
      <path
          d="M.77,2.36A27.91,27.91,0,0,0,8.62.82C17,1.9,23,3.75,26.33,6.21a41.54,41.54,0,0,1,1.54,8.42c0,1.49-.35,2.21-1.33,2.62-1.34.56-5.34.92-12.06.92-2.62,0-4.62-.1-5.86-.2A46.13,46.13,0,0,0,27.36,30.44l-.2,1.23a11.05,11.05,0,0,0-6.42,3.65,40.3,40.3,0,0,1-14.84-8c-.56,4.83-1.23,7.29-1.54,7.29-.77,0-1.95-.72-3.69-1.9C.1,32.39,0,31.78,0,30.7c0-.67.05-1.49.05-2.52A74.17,74.17,0,0,1,3.34,6.52a21.52,21.52,0,0,0-3.19-1ZM22.69,13C20,10.78,15.81,9.75,9.91,9L8.32,16.27C14.94,15.76,19.92,14.42,22.69,13Z" />
      <path
          d="M47.74,32.34C43.53,34,40.45,35,38.5,35a5.16,5.16,0,0,1-5.18-3.39l-.72.87h-.87c-.62,0-.67-1.38-.67-5.18a59.44,59.44,0,0,1,5.13-24A27.72,27.72,0,0,1,48.31.82c4.31,0,6.77.51,6.93.77a5,5,0,0,1,.3,2.05,2.52,2.52,0,0,1-.2.88L53.8,8a68.29,68.29,0,0,0-10,1.69,70.91,70.91,0,0,0-3.85,6c1.28-.05,2.62-.1,4.06-.1,6.05,0,9.13,1.74,9.13,2.15a2.32,2.32,0,0,1,0,.41L52.31,21a41.31,41.31,0,0,0-15.09,5.8c.36,1.84,1.85,2.77,4.46,2.77a19.19,19.19,0,0,0,8.88-2.67l.42.51A12.73,12.73,0,0,0,47.74,32.34Z" />
      <path
          d="M65.76,12.37a14.42,14.42,0,0,1-2.67.36c-3.85,0-5.8-3.13-5.8-9.34a11.24,11.24,0,0,1,.1-1.34,18.8,18.8,0,0,1,5.8-.87A40.68,40.68,0,0,1,74,2.87c3.34.93,4.68,1.19,4.88,2l.87,4,.82.36-.05,2.26c-1.69-.11-3.28-.16-4.72-.16-.87,0-1.74.05-2.51.11A46.21,46.21,0,0,1,74.64,17c-.61,3.85-2.72,9.24-6,16.33l-2.52.87-1.59,3.18-1.8-.72C64,28.39,64.94,20.33,65.76,12.37Z" />
      <path
          d="M88.76,2.21c.72-1.13.82-1.39,2-1.39h1.13c.77,0,1.13.05,1.54.62a18.29,18.29,0,0,1,3.08,6.88c0,1.69-3.69,10.67-7.29,27.1h-.36c-2.15,0-4.77-2.57-4.77-6.72a65,65,0,0,1,2-11.55L84,12A48.89,48.89,0,0,1,88.76,2.21Z" />
      <path
          d="M103.08,2.21,105.55.92c4.77.83,7.08,1.39,7.08,2.06a2.42,2.42,0,0,1,0,.41L112,5.75,114.73,8a79.66,79.66,0,0,0,3.91,17.91c2.92-4.87,5.23-12.21,6.46-21.76A35.9,35.9,0,0,1,127.93.67l.61.2c-2.77,23-6.52,34.35-9.6,34.35a1.58,1.58,0,0,1-.61-.16L116,33.88c-1.9-.92-5.39-7.75-7.6-19-.72,5-2.93,11.14-4.88,18.79-.2.87-.15,1.59-1.23,1.59-.36,0-1.85-1.39-4-4.26A128.13,128.13,0,0,1,103.08,2.21Z" />
      <path
          d="M141.74,1.8c-.05-.31-.11-.52-.11-.57s3-1.23,6.52-1.23c.52,0,.62.15.88.72l1.59-.1a.82.82,0,0,1,.72.3l.46.62c.36.46.72,3.64.72,9.29,0,2.05-.26,4.31-.31,6.83l1.8-.16h.3c.31,0,.36.11.36.62,0,3.13-.77,5.39-1.74,5.39h-.16a8.11,8.11,0,0,1-2,2c-.25,1.6-.51,3.29-.87,5l-4.36,2.67-.36,2.06-1.49.66a.71.71,0,0,1-.36.11c-.1,0-.15,0-.15,0a33.28,33.28,0,0,0,.61-6.83c0-1,0-1.95-.05-2.72-3.39.15-6.47.25-9.19.31-2.56,6-2.67,8.93-3.54,8.93H131l-.52,1.38c-.36,0-.71,0-1.07,0-.62,0-1-.15-1-.35a82.49,82.49,0,0,1,2.51-10.07,6.41,6.41,0,0,1-1.54-2.61,5.62,5.62,0,0,1-.87-2.67c0-.31.1-.47.87-.52l3.08-.31c1.85-7.34,4-11.9,4.62-14.32,2.67-1.54,4.27-2.46,5-2.77Zm2.31,16.89A68,68,0,0,0,143,6.57c-2.52,4.88-4.21,9.39-6,13.35Z" />
    </svg>

  </div>

  <router-view v-fade-in="$store.state.appDataLoaded" :transition-duration="700"/>


</template>

<script>
import {set_token} from "./store/axios";
export default {

  created() {
    const params = { __t:'', lt:'' };
    const param_t = (new URL(  window.location.href )).searchParams.get('__t');
    const param_lt = (new URL(  window.location.href )).searchParams.get('lt');

    if( param_t ) {
      params.__t = param_t;
      params.lt = param_lt;
    }


    this.$store.dispatch( 'setAppData', {
      success: ( data ) => {
        const appData = data.appData || { };

        let ckEditorCSS = document.createElement('link');

        ckEditorCSS.rel = 'stylesheet';
        ckEditorCSS.href = appData.ckEditorCSS;
        const head = document.querySelector('head');
        if( ckEditorCSS.href && head ) {
          head.appendChild( ckEditorCSS );
        }


        if( !appData.isLoggedIn ) {
          //set_token(false);
        }

        if( appData.hasLoginData ) {
          this.$store.commit( 'setAfterLogin', () => {
            this.$router.push( '/' );
          })
          this.setTokenAndRedirectToNext( appData.authResponse );
        }

        this.changeTitle( );
      },
      params
    });


    window.addEventListener('message', (event) => {


      // alert('RR');

      //console.log({event})

      const token = (new URL(  window.location.href )).searchParams.get('__t');
      const tokenKey = (new URL(  window.location.href )).searchParams.get('__tk');
      event.target.localStorage.setItem( tokenKey, token)

    });


  },
  computed: {
    globalVars: {
      get() { return this.$store.state.appData },
      set( val ) {  }
    },

  },
  methods:{
    changeTitle(){
      const projectName = this.$store.state.appData.name;
      let title = this.$route.meta.title;

      if( typeof title == 'string' ) {
        const vars = this.globalVars || {};
        title = title.replace(/\$\{([a-z0-9]+)\}/ig, ( match, group ) => vars[ group ] );
      }

      document.title = title || projectName;
    }
  },
  watch: {
    globalVars(){
      this.changeTitle();
    },
    '$route'() {
      this.changeTitle();
    }
  },
}
</script>

<style>
  .html-text img {
    display: inline-block;
  }
</style>
