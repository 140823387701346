import axios, {$axios, catch_handler, then_handler} from "../axios";

export default {
    namespaced: true,
    state: {
        tags: {},
        posts: {},
        post: {},
        post_profiles: {}
    },
    mutations: {
        setTag(state, tags) {
            state.tags = tags
        },
        setPost(state, posts) {
            state.posts = posts
        },
        setSinglePost(state, post) {
            state.post = post
        },
        setPostProfile(state, post_profiles) {
            state.post_profiles = post_profiles
        },
        addNewPost(state, post = {}) {
            state.posts.push(post)
        },
        addNewPostComment(state, data = {}) {
            console.log(data);
            state.posts[data.post_id].comments.push(data.comment)
        }
    },
    actions: {
        async tagData({state, commit}, params = {}){
            params = params || {};

            return await axios().get('/student/tags', {params: params.params})
                .then((response) => {
                    commit('setTag', response.data.tags)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async postData({state, commit}, params = {}){

            
            params = params || {};
            return await axios().get('/student/posts', {params: params.params})
                .then((response) => {
                    commit('setPost', response.data.posts)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async postSingleData({state, commit}, params = {}){
            params = params || {};
            return await axios().get(`/student/posts/${params.params.id}`, {params: params.params})
                .then((response) => {
                    commit('setSinglePost', response.data.post)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async postProfileData({state, commit}, params = {}){
            params = params || {};
            return await axios().get(`/student/post-profiles`, {params: params.params})
                .then((response) => {
                    commit('setPostProfile', response.data.profilePosts)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async submitPost({state, commit}, params = {}){
            params = params || {};
            return await axios().post('/student/post-create', params.data, {
                headers: {
                    // ContentType: "application/x-www-form-urlencoded; charset=utf-8;"
                }
            }).then(then_handler(params)).catch(catch_handler(params));
        },
        async submitLike({state, commit}, params = {}){
            params = params || {};

            return await axios().post('/student/like-create', {
                type : params.data.type,
                post_id: params.data.post_id,
            })
            .then((res) => {
                const post = Object.values(state.posts).find((post) => {
                        return post.id == params.data.post_id;
                    });

                if (post) {
                    post.totalLikes = res.data.totalLike;
                }

                if (state.post.likes) {
                    state.post.totalLikes = res.data.totalLike;
                }
            })
            .catch(catch_handler(params));
        },
        async submitCommentLike({state, commit}, params = {}){
            params = params || {};

            return await axios().post('/student/like-create', {
                type : params.data.type,
                comment_id: params.data.comment_id
            })
            .then((res) => {
                const commentId = res.data.like.commentId;

                Object.values(state.posts).forEach((post) => {
                    const comment = Object.values(post.comments).find((comment) => {
                        return comment.id == commentId;
                    });

                });

                comment.comments.push(res.data.comment);
            })
            .catch(catch_handler(params));
        },
        async submitComment({state, commit}, params = {}){
            params = params || {};

            return await axios().post('/student/comment-create', {
                post_id: params.data.post_id,
                comment_id: params.data.comment_id,
                body : params.data.body
            })
            .then((res) => {
                const postId = res.data.comment.postId;
                const commentId = res.data.comment.commentId;   

                const post = Object.values(state.posts).find((post) => {
                    return post.id == postId;
                });

                if (post) {
                    if(postId && commentId == 0) {
                        post.comments.push(res.data.comment);
                    } else {
                        const comment = Object.values(post.comments).find((comment) => {
                            return comment.id == commentId;
                        });
    
                        comment.comments.push(res.data.comment);
                    }
                }

                if (state.post.comments) {
                    if(commentId == 0) {
                        state.post.comments.push(res.data.comment);
                    } else {
                        const comment = Object.values(state.post.comments).find((comment) => {
                            return comment.id == commentId;
                        });
                        comment.comments.push(res.data.comment);
                    }
                }
            })
            .catch(catch_handler(params));
        },
        async getLike({state, commit}, params = {}) {
            params = params 
        }
    },
    getters: {
        tags(state) {
            return state.tags
        },
        posts(state) {
            return state.posts
        },
        post(state) {
            return state.post
        },
        post_profiles(state) {
            return state.post_profiles
        }
    },
    modules: {

    }
}
