import axios, {$axios, catch_handler, then_handler} from "../axios";
import cart from "../../models/cart";
export default {
    namespaced: true,
    state: {
        programProducts: [],
        customOrderItems: [],
        cartItems: [],
        orderData: {
            coursePrice:0,
            productsPrice: 0,
            discount:0,
            deliveryCharge:0,
        },
        items: []
    },
    mutations: {},
    actions: {},
    getters: {},
    modules: {}
}