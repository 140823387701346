import axios, {$axios, catch_handler, then_handler} from "../axios";
import {v4 as uuidv4} from "uuid";

export default {
    namespaced: true,
    state: {
        programsPrice: 0,
        productsPrice: 0,
        discount: 0,
        deliveryCharge: 0,
        paidAmount: 0,
        items: [],
        participant: { },
        productItemInput: [],
        mandatoryProductItems: [],
        otherProductItems: [],
        changedOrderItems: []
    },
    mutations: {
        /**
         * Setting the order data
         * @param state
         * @param order
         * @param participant
         */
        setOrder( state, { order, participant }) {
            if( order ) {
                state.programsPrice = order.programsPrice || 0;
                state.productsPrice = order.productsPrice || 0;
                state.discount = ( order.productsDiscount || 0 ) + ( order.packagesDiscount || 0 );
                state.paidAmount = order.totalPaid || 0;
                state.deliveryCharge =  order.deliveryCharge || 0 ;
                state.items = order.items;
                state.participant = participant;
                state.discount = participant.programDiscount
                this.commit( 'products/setProgramProducts', participant.programProducts );
                this.commit( 'order/setCartItems' );

            }
        },

        /**
         * Setting Cart Items from program products
         * @param state
         */
        setCartItems( state ){

            if( state.participant && state.participant.programProducts && Array.isArray( state.participant.programProducts ) ) {

                const eachProduct = ( item ) => {

                    const productItem = ProductItem( item , {} );

                    if( productItem.ordered ) {

                        this.commit( "order/setChangedOrderItems", { orderItem: productItem, event: { } });
                    }
                    return productItem;
                }

                state.mandatoryProductItems = state.participant.programProducts
                    .filter( item => item.isMandatory ).map( eachProduct );

                state.otherProductItems = state.participant.programProducts
                    .filter( item => !item.isMandatory ).map( eachProduct );
            }

        },

        /**
         * Setting the order item changes
         * @param state
         * @param orderItem
         * @param event
         */
        setChangedOrderItems( state,{ orderItem, event }){
            let price, quantity = 1, discount = 0, key, marketplaceId, isFree, isMandatory;



            if( orderItem ) {
                price = orderItem.price;
                isFree = orderItem.isFree;
                isMandatory = orderItem.isMandatory;
                quantity = orderItem.quantity;
                discount = orderItem.discount;
                key = orderItem.key;
                marketplaceId = orderItem.marketplaceId;
            }

            const existedIndex = state.changedOrderItems.findIndex( item => item.key === orderItem.key );

            //console.log( '[event.type]', event.type, event.target.checked, existedIndex );

            if( existedIndex > -1 ) {
                if( event && ( event.type === 'click' || ( event.type === 'change' && event.target.checked === false ) ) ) {
                    state.changedOrderItems.splice( existedIndex, 1 );
                } else {
                    state.changedOrderItems[existedIndex].quantity = quantity;
                }

            } else {
                state.changedOrderItems.push({ price, quantity, discount, key, marketplaceId, isFree, isMandatory  });
            }
        }

    },
    actions: {
        async single({ state, commit }, data ) {
            const loadFromServer = typeof data.loadFromServer == 'boolean' ? data.loadFromServer:(( data.params || { }).with);

            const rejectedResult = ( message ) => ({
                order: null,
                loadFromServer,
                message: message,
                loadedFromServer: false,
            });

                if( data.paymentableType && data.paymentableId ) {

                    return new Promise((resolve,reject) => {
                        axios( ).get( `/student/participant-order-data/${data.paymentableType}/${data.paymentableId}` , { params: data.params } )
                            .then( then_handler( data, ( result ) => {
                                try{

                                    commit( 'setOrder', (result.data || {}).order );
                                    resolve({
                                        order: (result.data || {}).order,
                                        loadedFromServer: true,
                                        loadFromServer,
                                        message:  (result.data || {}).message,
                                    });

                                } catch (err){
                                    return Promise.reject( rejectedResult( err ) );
                                }
                            }))
                            .catch( catch_handler( data, ( result ) =>
                                reject( rejectedResult( (result.data || {}).message) )
                            ));
                    });

                } else {
                    return Promise.reject( rejectedResult( 'Incorrect input' ) );
                }


        },
        async saveItems( { state, commit }, data ){
            return new Promise((resolve, reject) => {
                const order_id = data.orderId;
                const orderItems = data.orderItems;
                const reference = data.reference;
                const item = axios().post(`/order/${order_id}/items`, { orderItems, reference } );
                item.then( ({data}) => {
                    //todo:: Change state after order item insert or update
                    resolve({reference})
                }).catch( reject )
            })
        }
    },

    getters: {
        totalPrice: ( state ) => state.programPrice + state.productsPrice,
        programMandatoryProductData: ( state ) => {
            if( Array.isArray( state.mandatoryProductItems )) {

                let price = 0;
                let discount = state.discount;


                state.mandatoryProductItems.forEach( ( productItem ) => {

                    if( productItem ) {
                        if( !productItem.isFree ){
                            price += ( productItem.price || 0 );
                            discount += ( productItem.discount || 0 );
                        }
                    }

                });

                return { price, discount };

            }
        },
        programProductData: ( state ) => {
            let price = 0;
            let discount = 0;

            if( state.changedOrderItems && Array.isArray( state.changedOrderItems ) ) {
                state.changedOrderItems.forEach( item => {
                    if( item ) {
                        if( !(item.isFree || item.isMandatory) ) {
                            price += ( item.price || 0 );
                            discount += ( item.discount || 0 );
                        }
                    }
                });
            }

            //console.log( price );

            return { price, discount }
        }
    },
    modules: {

    }
}

function ProductItem(
    item, { isMandatory, title, orderItemId, marketplaceId, type, price,quantity, discount, isFree, ordered }
){

    if( item ) {


        const marketplace = item.marketplace || { };
        const orderItem = marketplace.orderItem;

        isMandatory = item.isMandatory;
        title = marketplace.name;
        marketplaceId = marketplace.marketplaceId
        isFree = item.isFree;
        // price = isFree ? 0: ( typeof marketplace.price == 'undefined' ? price: marketplace.price );
        price = ( typeof marketplace.price == 'undefined' ? price: marketplace.price );
        quantity = 1;
        discount = item.discount;

        ordered = typeof ordered == 'boolean'? ordered: false;

        if( orderItem ) {
            title = orderItem.title || (title || 0);
            price = typeof orderItem.price != 'undefined' ? orderItem.price: (price || 0);
            quantity = typeof orderItem.quantity != 'undefined' ? orderItem.quantity: (quantity || 1);
            discount = typeof orderItem.discount != 'undefined' ? orderItem.discount: (discount || 0);
            orderItemId = orderItem.orderItemId || (orderItemId || null);
            ordered = true;
        }


        // console.log( "__________", (item.marketplace || { }).name, item.appliedDiscount );

    }


    return {
        title: title || '(untitled)',
        orderItemId: orderItemId || 0,
        marketplaceId: marketplaceId || 0,
        key: orderItemId || (`unordered_${uuidv4()}`),
        type: type || 'Product',
        price: price || 0,
        quantity: quantity || 1,
        discount: discount || 0,
        isFree: typeof isFree =='boolean' ? isFree: false,
        ordered: typeof ordered =='boolean' ? ordered: false,
        isMandatory: typeof isMandatory =='boolean' ? isMandatory: false,
    };

}

