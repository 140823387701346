import {setIntent, setStudentLoginIntent} from "../store/local-storage";
import {isLoggedIn} from "../store/axios";

const authenticateBeforeNext = (to, from, next ) => {

    setIntent( to.fullPath );

    if ( !isLoggedIn( ) ) {
        setStudentLoginIntent( to.fullPath );
        next({ path: '/join' })
    }
    else next()
}


export { authenticateBeforeNext }