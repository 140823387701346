import axios, {catch_handler, get_token, set_csrf_cookie, set_token, then_handler} from "../axios";
import {getStudentLoginIntent, getUserData, setUserData} from "../local-storage";

export default {
    namespaced: true,
    state: { },
    mutations: {

    },
    actions: {
        async availableBatches({state}, params ){
            params = params || {};

            return await axios( ).get( '/batches', {params: params.params} )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async participantReviews({state}, params ){
            params = params || {};

            return await axios( ).get( '/participant-reviews', {params: params.params} )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async blogViews({state}, params){
            params = params || {};

            return await axios().get('/home-posts', {params: params.params})
                .then(typeof params.success == 'function' ? params.success : () => {})
                .catch( ({response}) => typeof params.error == 'function' ? params.error(response) : () => {});
        },
        async courseList({state}, params){
            params = params || {};

            return await axios().get('/courses', {params: params.params})
                .then(typeof params.success == 'function' ? params.success : () => {})
                .catch( ({response}) => typeof params.error == 'function' ? params.error(response) : () => {});
        },

        async courseContent({state}, params){
            params = params || {};

            return await axios().get(`/session-topics/${params.params.program_id}`, {params: params.params})
                .then(typeof params.success == 'function' ? params.success : () => {})
                .catch( ({response}) => typeof params.error == 'function' ? params.error(response) : () => {});
        },

        async noticeViews({state}, params){
            params = params || {};

            return await axios().get('/notices', {params: params.params})
                .then(typeof params.success == 'function' ? params.success : () => {})
                .catch( ({response}) => typeof params.error == 'function' ? params.error(response) : () => {});
        },
        async teacherViews({state}, params){
            params = params || {};

            return await axios().get('/teachers', {params: params.params})
                .then(typeof params.success == 'function' ? params.success : () => {})
                .catch(({response}) => typeof params.error == 'function' ? params.error(response) : () => {});
        },
        async faqsViews({state}, params){
            params = params || {};

            return await axios().get('/faqs', {params: params.params})
                .then(typeof params.success == 'function' ? params.success : () => {})
                .catch(({response}) => typeof params.error == 'function' ? params.error(response) : () => {});
        },

        async branches( {state}, params ){
            params = params || {};

            return await axios( ).get( '/branches', {params: params.params}  )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
    },
    getters: {

    },
    modules: {

    }
}
