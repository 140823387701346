import AXIOS from "axios";
import { createStore } from 'vuex';
import axios, { base_url, getHeaders, setCsrfCookieLoaded, set_csrf_cookie } from "./axios";
export default createStore({
  state: {
    header: {
      class: 'p-3',
      isHidden: false,
    },
    scrollTop: -1,
    afterLoginAction: null,
    afterLoginActionCalled: false,
    appData : {
      name: 'Retina Academy'
    },
    appDataLoaded: false,
    flashMessage: {}
  },
  mutations: {
    setScrollTop(state, top ){
      state.scrollTop = top;
    },
    setHeaderData(state, data ){
      if( data.header_class ) {
        state.header.class = data.header_class || 'p-2';
      }

      if( typeof data.is_hidden  == 'boolean' ) {
        state.header.isHidden =  data.is_hidden ;
      }
    },
    setFlashMessage( state, flashMessage ){
      state.flashMessage = flashMessage;
    },
    setAfterLogin( state, action ){
      state.afterLoginAction = action;
    },
    changeAppData( state, data ){
      state.appData = data
      state.appDataLoaded = true;
    }
  },
  actions: {

    async createBranchSwitchToken( {state, commit }, params ){
      return await axios().post('student/create-branch-switch-token' )
          .then(({data}) => typeof params.success == 'function' ? params.success(data): () => {} )
          .catch( ({response}) => typeof params.error == 'function' ? params.error(response): () => {}  )
    },
    async setAppData( {state, commit }, params ){
      params = params || {};

      const a = await set_csrf_cookie( ).then( ax => {
        setCsrfCookieLoaded();

        AXIOS.get( base_url + '/app-data',
            {
              headers: getHeaders(),
              params: params.params
          }).then( ({data}) => {

            if( typeof params.success  == 'function' ) {
              params.success( data );
            }

            commit('changeAppData', data.appData || {});

          }).catch( ({response}) => {

            setTimeout(() => {
              this.dispatch( 'setAppData', params );
            }, 5000);

          });

      }).catch( err => setTimeout(() => {
        this.dispatch( 'setAppData', params );
      }, 5000) )

    },

  },
  getters: {

  },
  modules: require('./modules').default
});
