import axios, {$axios, catch_handler, then_handler} from "../axios";

export default {
    namespaced: true,
    state: {
        saqs: {},
    },
    mutations: {
        setSaq(state, saqs) {
            state.saqs = saqs
        },
    },
    actions: {
        async saqData({state, commit}, params = {}){
            params = params || {};

            return await axios().get('/saqs', {params: params.params})
                .then((response) => {
                    commit('setSaq', response.data.saqs)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    getters: {
        saqs(state) {
            return state.saqs
        },
    },
    modules: {

    }
}
