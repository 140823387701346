<template>
  <div class="inset-0 flex justify-center items-center z-50" :class="`${background} ${opacity} ${position}`">
      <spinner v-if="loading"></spinner>
      <slot v-else></slot>
  </div>
</template>

<script>
import Spinner from "./spinner";
export default {
  name: "overly",
  components: {Spinner},
  props: {
    background: { type:String, default: "bg-gray-700" },
    opacity: { type:String, default: "bg-opacity-75" },
    position: { type:String, default: "absolute" },
    loading: { type:Boolean, default: false },
  }
}
</script>

<style scoped>

</style>