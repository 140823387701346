<template>
  <router-link
    class="
      w-full
      md:w-auto
      min-w-max
      px-2.5
      lg:px-4
      py-1
      mx-0.5
      lg:mx-1
      rounded-sm
      font-semibold
      text-gray-700
    "
    exact-active-class="md:bg-blue-600 bg-white text-blue-500 md:text-white"
    :to="to"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: String,
  },
};
</script>