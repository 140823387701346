import axios, {$axios, catch_handler, then_handler} from "../axios";
import cart from "../../models/cart";

function getCartItemsAdjustment( items, key ){
    let total = 0;
    items.forEach( (item) => {
        if( item.isDeleted && ( item.orderItemExists || item.orderItemId ) ) {
            total -= ( item[key] || 0 );
        }else if( !item.isDeleted && !( item.orderItemExists || item.orderItemId ) ) {
            total += ( item[key] || 0 );
        }
    });
    return total;
}


function getMatchesData( data,   extents = {}){
    const matches = {};

    if( data.isPackage ) {
        matches.isPackage = true;
        matches.packageId = data.packageId;
    } else {
        matches.isPackage = false;
        matches.productId = data.productId;
    }

    return {...matches, ...extents }
}

export default {
    namespaced: true,
    state: {
        programProducts: [],
        customOrderItems: [],
        cartItems: [],
        orderData: {
            coursePrice:0,
            productsPrice: 0,
            discount:0,
            deliveryCharge:0,
        },
        items: []
    },
    mutations: {
        setItem( state, data ){


            if( data.item ) {
                const insert = data.insert || false;

                const matches = getMatchesData( data.item );
                const deleted = insert ? false : data.deleted;
                const filtered = state.items.filter( item => item.matches(  matches ));


                if( filtered[0] ) {

                    const index =  filtered[0].getIndex();
                    filtered[0].isDeleted = deleted;

                } else if ( insert ) {



                    const conditions = state.programProducts
                        .map( item => (
                            ( data.item.isPackage == true && data.item.packageId == item.packageId )
                                || ( data.item.isPackage == false && data.item.productId == item.productId )
                        ));


                    const item = cart( data.item );
                    if( conditions.indexOf(true) > -1 ) {
                        item.isProgramProduct = true;
                    }

                    state.items.push( item )
                }
            }
        },
        setItems(state, items){
            state.items = cart.collection( items  );
        },
        addCustomOrderItem( state, item ){
            if( !item.isProgramProduct ) {
                if( state.customOrderItems.filter(( orderItem ) =>
                    (item.isPackage && orderItem.packageId == item.packageId || item.isProduct && orderItem.productId == item.productId)
                ).length === 0 ){
                    state.customOrderItems.push( item );
                }
            }
        },
        addCartItem( state,  newItem ){
            let exists = false;
            state.cartItems.forEach( (item, index) => {
                if( item.orderable_type == newItem.orderable_type
                    && item.orderable_id == newItem.orderable_id
                    && item.is_program_product == newItem.is_program_product
                ) {
                    exists = true;
                    state.cartItems[index] = newItem;
                }
            });

            if( !exists ) {
                state.cartItems.push( newItem );
            }
        },
        removeCartItem( state, data ){

            if( !data.isProgramProduct ) {
                let i;

                const getKey = () => {
                    for( i = 0; i < state.customOrderItems.length; i++ ) {
                        let key = '';
                        switch( data.type ) {
                            case 1:
                                key = 'productId';
                                break;
                            case 2:
                                key = 'packageId';
                                break;
                        }
                        if( !state.customOrderItems[i].isProgramProduct && state.customOrderItems[i][key] == data.id ) {
                            return i;
                        }
                    }
                    return -1;
                }

                state.customOrderItems.splice( getKey(), 1 );
            }
        },
        setOrderData( state, data ){
            const orderData = {};
            orderData.coursePrice = data.programsPrice;
            orderData.productsPrice = data.productsPrice;
            orderData.discount = data.totalDiscount;
            orderData.deliveryCharge = data.deliveryCharge;

            state.orderData = orderData;

        },
        setProgramProducts(state, programProduct ){
            state.programProducts = programProduct;
        }
    },
    actions: {
        async getList( { state, commit }, params = {} ){

            params = params || { };
            return await axios( ).get( '/products', { params: params.params } )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        },
        async updateOrderItem({state}, params = {}){
            const orderId = params.order_id;
            params.data = params.data || {};

            params.data.participant_id = params.participant_id;
            params.data.orderable_type = params.orderable_type;

            if( params.deleting_item_ids ) {
                params.data.deleting_item_ids = params.deleting_item_ids;
            }

            if( params.orderable_id ) {
                params.data.orderable_id = params.orderable_id;
            }
            if( params.service_point_id) {
                params.data.service_point_id = params.service_point_id;
            }



            return await axios( ).post( `/order/${orderId}/items`, params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        },
        async removeOrderItem({state}, params={}){
            const orderId = params.order_id;
            const orderItemId = params.order_item_id;

            return await axios( ).delete( `/order/${orderId}/items/${orderItemId}delete`, params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        }

    },
    getters: {
        getCartItemPrice: ( state ) => getCartItemsAdjustment( state.items, 'price' ),
        getCartItemDiscount: ( state ) => getCartItemsAdjustment( state.items, 'discount' ),
        programsPrice: state =>  state.orderData.coursePrice,
        productsPrice: (state, getters) =>  ( state.orderData.productsPrice + getters.getCartItemPrice ),
        deliveryCharge: state =>  state.orderData.deliveryCharge,
        totalAmount: (state,getters) => (getters.programsPrice + getters.productsPrice + state.orderData.deliveryCharge),
        discount: (state, getters) =>  state.orderData.discount + getters.getCartItemDiscount,
        totalPayable: (state,getters) =>  getters.totalAmount - getters.discount,
        paidAmount: ( state, getters, rootState ) =>  rootState.payment.paidAmount,
        currentDue: ( state, getters ) =>  getters.totalPayable - getters.paidAmount,
        notInProgramItems: ( state, getters ) => {
            return state.items.filter( item => !item.isProgramProduct && !item.isDeleted );
        },
        inProgramItems: ( state, getters ) => {
            // return state.items;
            return state.items.filter( item => item.isProgramProduct && !item.isDeleted );
        },
        getSubmittingData:( state ) => {
            return {
                deleting: state.items.filter( item => item.orderItemId && item.isDeleted ).map( item => item.orderItemId ),
                updatingOrInserting: state.items.filter( item => !item.orderItemId && !item.isDeleted )
                    .map( item =>({
                        orderable_type: item.isPackage ? 2:1,
                        orderable_id: item.isPackage ? item.packageId : item.productId
                    })),
            }
        }
    },
    modules: {}
}
