<template>
  <nav-link to="/">Home</nav-link>
<!--  <nav-link to="/Branches">Branches</nav-link>-->
  <nav-link to="/courses">Courses</nav-link>
  <nav-link to="/about">About</nav-link>
  <nav-link to="/contact">Contact</nav-link>
  <nav-link to="/my/blog">Blog</nav-link>
</template>

<script>
import NavLink from "./nav-link.vue";
export default {
  components: { NavLink },
};
</script>