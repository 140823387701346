import axios, {catch_handler, then_handler} from "../axios";
import cart from "../../models/cart";


export default {
    namespaced: true,
    state: {
        paidAmount: 0,
        paymentList: [ ],
        paymentDataLoaded: false
    },
    mutations: {


    },
    actions: {

        async postOrderDataAndGetGetWayData({ state, commit, dispatch, rootState }, params = { } ){
            const data = params.data || {};


            if( params.orderId && data.participant_id ) {
                return await axios( )
                    .post( `student/payment/${params.orderId}` , data )
                    .then( then_handler ( params ) )
                    .catch( catch_handler ( params ) );
            }

        },

    },
    getters: {

    },
    modules: {

    }
}
