import axios, {catch_handler, get_token, set_csrf_cookie, set_token, then_handler} from "../axios";
import {getStudentLoginIntent, getUserData, setUserData} from "../local-storage";

async function setTokenToOhterDomain( domains, token, hashToken = ''){

    if( Array.isArray( domains ) ) {
        const container = document.createElement('div');

        container.id = '__ifrem__container__';

        container.style.display = 'none';

        document.body.append( container );

        let allLoaded = [];

        domains.forEach( domain => {

            allLoaded.push(new Promise(function(resolve, reject) {

                const iframe = document.createElement("iframe" );

                iframe.src = `http://${domain}/student/set-token/${token}/${hashToken}`;

                iframe.addEventListener('load', e => {
                    resolve(true);
                })

                container.append(iframe);
            }));

        });

        return await Promise.all(allLoaded);
    }

}

export default {
    namespaced: true,
    state: {
        is_logged_in: get_token( ) || false,
        userdata: getUserData( ) || false,
    },
    mutations: {
        setIsLoggedIn( state, status ){
            state.is_logged_in = status;
        },
        setUser( state, user ){
            setUserData( user );
            state.userdata = user;
        },
        setLogin( state, params  ){
            let token, tokenHash, user, branchDomains;

            if( params && params.data.token ) {

                const data = params.data;
                token = data.token;
                user = data.user;
                branchDomains = data.branchDomains;
                state.is_logged_in = true;
                tokenHash = data.tokenHash;

            }else if( params === false){
                token = false;
                user = null;
                state.is_logged_in = false;
            }
            set_token( token, tokenHash );

            this.commit( 'student/setUser', user );


            if( state.is_logged_in ) {

                if( typeof params.loggedIn == 'function' ) {
                    params.loggedIn( params );
                }

                setTokenToOhterDomain( branchDomains, token, tokenHash )
                    .then( d => console.log( d ) )
                    .finally( () => {
                        let container = document.getElementById('__ifrem__container__');
                        if(container) {
                            container.parentNode.removeChild(container);
                        }
                    });

            }
        }
    },
    actions: {

        async join({state, commit }, params = {} ){
            params = params || { };

            return await axios( ).post( '/student/join', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );

        },
        async confirm({ state, commit }, params = {} ){
            params = params || { };

            return await axios( ).post( '/student/confirm', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );

        },
        async register({ state, commit }, params = {} ){
            params = params || { };
            return await axios( ).post( '/student/register', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );

        },
        async login( { state, commit }, params = {} ){
            params = params || { };
            return await axios( ).post( '/student/login', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );

        },
        async profile( { state, commit }, params = {} ){
            params = params || { };
            return await axios( ).get( '/student/profile', { params: params.params } )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );

        },
        async editProfileData( { state, commit }, params = {} ){
            params = params || { };
            return await axios( ).post( '/student/profile/' + params.key + '/update', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );

        },
        async updateAreaAndAddress( { state, commit }, params = {} ){
            params = params || { };
            return await axios( ).post( '/student/area-and-address', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );

        },
        async changePassword( { state, commit }, params ){
            params = params || { };


            return await axios( ).post( '/student/change-password', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        },
        async setNewPassword( { state, commit }, params ){
            params = params || { };

            return await axios( ).post( '/student/set-new-password', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        },
        async checkUser( { state, commit }, params ){
            params = params || { };

            return await axios( ).post( '/student/check-user', params.data )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        },
        async logout( { commit }, params ){
            params = params || { };

            const afterThat = () => {
                commit( 'setLogin', false );
                if( typeof params.loggedOut == "function") {
                    params.loggedOut();
                }
            }

            return await axios( ).post( '/student/logout' )
                .then( () => { } )
                .catch( () => { } )
                .finally( afterThat );

        },

    },
    getters: {
        name : state => {
            const userData = state.userdata || {};
            return userData.name || '';
        },
        studentId : state => {
            const userData = state.userdata || {};
            return userData.id || '';
        }
    },
    modules: {

    }
}
