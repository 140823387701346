export default {

    install(app, options){

        const global = app.config.globalProperties;

        global.$extendRouteQueryParams = function ( queryParams, replaceOnly = false  ){

            if( typeof queryParams == 'object') {
                global.$router[`${replaceOnly ? 'replace':'push'}`]({
                    to: this.$route.path,
                    query: {
                        ...this.$route.query, ...queryParams
                    }
                });
            }
        }

        global.$resetRouteQueryParams = function ( queryParams, replaceOnly = false  ){

            if( typeof queryParams == 'object') {
                global.$router[`${replaceOnly ? 'replace':'push'}`]({
                    to: this.$route.path,
                    query: queryParams
                });
            }

        }


    }

}