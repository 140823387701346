import { isLoggedIn, set_token } from "../store/axios";
import {getStudentLoginIntent, setStudentLoginIntent} from "../store/local-storage";

export default {
    data() {
        return {
            programItem: {},
            formErrors: {},
            formMessage: '',
            formMessageStatus: 200,
            afterLoginActionCalled: false,
            afterEnroll: null,
        }
    },
    computed: { },
    methods: {

        clearLoginLocalStorageAndLogout(){
            set_token( false );
            this.$store.commit('student/setIsLoggedIn', false);
        },
        flashMessage( data ){
            setTimeout( this.$store.state.flashMessage.slideUp, 3, data )
        },
        enrollToProgram({ programId, programItem, nextPage, options, onSuccess, onError, showFlash }) {

            showFlash = typeof showFlash == 'undefined' ? true: false;

            if( confirm('Are you sure?\nWant to enroll') ) {
                this.authenticatedArea((next) => {
                    if( !programId ) {
                        programId = programItem ? (programItem.id || programItem.programId) : this.programItem.programId;
                    }


                    this.$store.dispatch("batch/enroll", {
                        data: {...{program_id: programId}, ...(options || {})},
                        success: ({data}) => {
                            const participant = data.participant || {};
                            if( showFlash ){
                                this.flashMessage({message: data.message, options: {statusCode: 200}});
                            }

                            if (participant && participant.due > 0) {
                                this.$router.push(`/my/payment/program/${participant.programId}`);
                            } else {
                                if( typeof onSuccess == 'function'  ) {
                                    onSuccess(data);
                                }else if (typeof this.afterEnroll == 'function') {
                                    this.afterEnroll(data);
                                } else {
                                    this.$router.push(next);
                                }
                            }

                        },
                        error: ({status, data}) => {
                            if (status === 400) {
                                if( showFlash ) {
                                    this.flashMessage({message: data.message, options: {statusCode: 400}});
                                }

                                if (data.alreadyEnrolled) {

                                    //console.log({data})
                                    this.$router.push( "/my/courses" );

                                } else {
                                    if( typeof onError == 'function') {
                                        onError( data, status );
                                    } else {
                                        this.$router.push(next);
                                    }
                                }
                            }
                        }
                    });

                }, "/my/courses");
            }
        },
        scrollTop(){
            window.scrollTo({ top: 0 });
        },
        hasFormError( name ) {
            if( this.formErrors[name] && Array.isArray( this.formErrors[name] ) ) {
                return this.formErrors[name].length > 0;
            }
            return false;
        },
        setFormMessage( msg, code = 200 ) {

            if( typeof msg == 'string' ) {
                this.formMessage = msg;
                this.formMessageStatus = code;
            }else  {
                this.formMessageStatus = msg?  ( msg.status ? msg.status : 400 ) :400;
                this.formMessage = msg ? (msg.data ? msg.data.message:''):'';
            }

        },
        setFormErrors(errors) {
            this.formErrors = errors;
        },
        setTokenAndRedirectToNext(data) {

            this.$store.commit( 'student/setLogin', {
                data,
                loggedIn: () => {


                    const next = getStudentLoginIntent() || '/my/dashboard';
                    const action = this.$store.state.afterLoginAction;

                    if( typeof action == 'function' ) {
                        action( next );
                    }else {
                        this.$router.push( next )
                    }
                }
            });

        },
        authenticatedArea(action, nextRoute ){
            if( typeof action == 'function') {
                setStudentLoginIntent( nextRoute );


                if( this.isLoggedIn( ) ) {
                    action( nextRoute );
                } else {
                    this.$store.commit( 'setAfterLogin', ( next ) => {
                        action(next);
                        this.$store.commit( 'setAfterLogin', null );
                    });
                    this.$router.push({ path: '/join' })
                }
            }

        },
        isLoggedIn() {
            return this.$store.state.student.is_logged_in;
        },
        getUserData(key) {

        }
    },

}