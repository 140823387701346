import axios, {$axios, catch_handler, then_handler} from "../axios";

export default {
    namespaced: true,
    state: {
        faqs: {}
    },
    mutations: {
        SET_FAQs(state, faqs) {
            state.faqs = faqs
        }
    },
    actions: {
        async faqData({state, commit}, params = {}){
            params = params || {};

            return await axios().get('/faqs', {params: params.params})
                .then((response) => {
                    commit('SET_FAQs', response.data.faqs)
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async feedback({state, commit}, params = {}){
            params = params || {};

            return await axios().post('/faq-feedback', {
                faq_id: params.data.faq_id,
                feedback : params.data.feedback,
            })
            .then(then_handler(params))
            .catch(catch_handler(params))
        }
    },
    getters: {
        faqs(state) {
            return state.faqs
        }
    },
    modules: {

    }
}
