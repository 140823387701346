<template>
  <router-link
    class="
      w-full
      md:w-auto
      min-w-max
      px-4
      py-1
      ml-1
      rounded-sm
      text-white
      bg-green-600
    "
    exact-active-class="bg-blue-500 text-white"
    to="/join"
  >
    Join
  </router-link>
</template>