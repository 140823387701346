import axios, {$axios, catch_handler, then_handler} from "../axios";
import {v4 as uuidv4} from "uuid";

export default {
    namespaced: true,
    state: {
        programProducts:[]
    },
    mutations: {
        setProgramProducts( state, products ){
            if( Array.isArray( products) ) {
                state.programProducts = products.map( ( item ) => {


                    let unorderedItemId = `__custom_${uuidv4()}`;
                    if( item.marketplace ) {
                        const orderItem = ( item.marketplace || {} ).orderItem || { };
                        unorderedItemId =  orderItem.orderId || unorderedItemId;
                    }

                    return {...item, ...{ itemId: unorderedItemId }}
                });
            }
        }
    },
    actions: {
        async getList( { state, commit }, params = {} ){

            params = params || { };
            return await axios( ).get( '/products', { params: params.params } )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        },
    },
    getters: {


    },
    modules: {

    }
}
