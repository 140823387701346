<template>
  <div :class="color+ ' '+ (background ? 'px-3 py-2 rounded shadow':'') " class="text-sm" v-if="_show">
    <template v-if="_statusMessage">{{ _statusMessage }}</template>
    <template v-else><slot></slot></template>
  </div>
</template>

<script>

import {getColorByCode} from "@/core/color";
export default {
  name: "status-message",
  props: {
    response: { type: Object, default:{ status: 200 } },
    timeout: {  default: 0 },
    background: { type:Boolean, default: false },
    message: { type: String, default: ''},
    statusCode: { type:Number, default: 200 },
  },
  data(){
    return {
      _message:"",
      _code:"",
      _timeoutId: 0,
      _show: true,
      _timeoutEnabled: false,
    }
  },
  computed:{
    _statusMessage:{
        set( value ){
          clearTimeout( this._timeoutId )
          this._message = value;
          this.$emit('update:message', value);

          this._show = true;
          if( this.timeout && this._timeoutEnabled ) {
            this._timeoutId = setTimeout( this.resetMessageValue, this.timeout )
          }

        },
        get(){
          return this._message;
        }
    },
    _statusCode:{
      set( code ){
        this._code = code;
        this.$emit( 'update:statusCode', code );
      },
      get(){
        return this._code;
      }
    },
    color(){
      return getColorByCode( this._statusCode, this.background ).toString()
    },
  },
  // beforeUpdate() {
  //   this.reset();
  // },
  // beforeUnmount() {
  //   this.reset();
  // },
  methods:{
    resetMessageValue(){
      this._show = false
    },
    reset(){
      this._statusCode = 400;
      this._statusMessage = '';
    }
  },
  created( ) {
    this._statusCode = this.statusCode;
    this._code = this.statusCode;
  },
  watch:{
    message( msg ){

      this._statusMessage = msg;
    },
    statusCode( code ){
      this._statusCode = code;
    },
    response( r ){

      if( r ) {
        this._statusCode =  r.status ? r.status : 400;
        this._statusMessage = r ? (r.data ? r.data.message:'') : '';
      } else {
        this._statusCode = 400;
        this._statusMessage = '';
      }

      if( this._statusMessage ) {
        this._timeoutEnabled = true;
      } else {
        this._timeoutEnabled = false;
      }

    }
  }
}
</script>

<style scoped>

</style>