<template>

    <btn class="py-2 relative w-full text-center " variant="warning"
         :title="`${participant.due}`"
         :router-path="'/my/payment' +'/program/' + ( participant.program_id || participant.programId ) "
    >
      <div>Payment</div>
    </btn>

</template>

<script>
import Btn from "../../../../components/btn";
export default {
  name: "payment-link",
  components: {Btn},
  props: { participant: Object },
  methods: {
    getPercentStyleWidth( value, price ){
      let percent = Math.round((value/price) * 100);
      return `width: ${percent}%`;
    }
  },
  computed: {
    paymentDuePercent() {
      const [price, value] = [this.participant.programPrice, this.participant.due];
      return this.getPercentStyleWidth( value, price );
    },
    paymentPaidPercent(){
      const [ price, value ] = [ this.participant.programPrice, this.participant.paidAmount ];
      return this.getPercentStyleWidth( value, price );
    }
  }
}
</script>

<style scoped>

</style>