import axios, {$axios, catch_handler, then_handler} from "../axios";

export default {
    namespaced: true,
    state: {
        reviews: { },
        programs: { },
        institutes: { },
    },
    mutations: {
        setReview(state, reviews) {
            state.reviews = reviews
        },
        setProgram(state, programs) {
            state.programs = programs
        },
        setInstitute(state, institutes) {
            state.institutes = institutes
        },
    },
    actions: {
        async callReviewsCreateApi({state, commit}, params = {}){

            params = params || { };

            return await axios()
                .get('/student/participant-reviews-create', { params: params.params } )
                .then((response) => {
                    commit('setProgram', response.data.programs)
                    commit('setInstitute', response.data.institutes)
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        async submitReview({state, commit}, params = {}){

            params = params || { };

            return await axios()
                .post('/student/participant-reviews/' + params.data.programId , {
                    institute_id: params.data.institute,
                    unit: params.data.unit,
                    review: params.data.review,
                    position: params.data.position,
                } )
                .then( then_handler ( params ) )
                .catch( catch_handler ( params ) );
        },
        async callAllReviews({state, commit}, params = {}){

            params = params || { };

            return await axios()
                .get('/participant-reviews', { params: params.params } )
                .then((response) => {
                    commit('setReview', response.data.reviews)
                })
                .catch((error) => {
                    console.log(error);
                });

        },
    },
    getters: {
        reviews (state) {
            return state.reviews;
        },
        programs (state) {
            return state.programs;
        },
        institutes (state) {
            return state.institutes;
        }
    },
    modules: {

    }
}
