<template>
  <component
    :is="rootComponent"
    :to="routerPath"
    :href="href || routerPath"
    :type="type"
    :class="`font-semibold cursor-pointer ${btnClasses} ${ border ? 'shadow shadow-red-600 hover:shadow-none ':'  '}`"
    class="rounded inline-block"
  >
    <div class="flex items-center">
      <spinner v-if="loading" />
      <div class="text-center w-full">
        <slot/>
      </div>
    </div>
  </component>
</template>

<script>
import Spinner from "./spinner";
import {getColor} from "../core/color";
export default {
  props: {
    href: { type: String },
    disabled: { default: false },
    variant: { type: String, default: "primary" },
    loading: { type: Boolean, default: false },
    type: { type: String, default: "" },
    size: { type: String, default: "" },
    routerPath: { type: String, default: "" },
    border: { type: Boolean, default: true },
  },
  name: "btn",
  components: { Spinner },
  computed: {

    getSize(){

      switch ( this.size ) {
        case "xs":
          return 'text-xs px-3 py-1 ';
        case "sm":
          return 'text-sm px-3 py-1 ';
        case "md":
          return 'text-base px-3 py-2 ';
        case "lg":
          return 'text-base px-5 py-3 ';
        case "xl":
          return 'text-lg px-6 py-3 ';
        default:
          return 'text-base px-3 py-1'
      }
    },

    btnClasses() {
      let isBordered = true;

      if( typeof this.border == 'boolean') {
        isBordered = this.border;
      }


      return ( this.disabled
        ? " bg-gray-300 text-white pointer-events-none "
        : getColor( this.variant, { border: this.border, type: 'btn' } ) ) + ' ' + this.getSize;
    },

    rootComponent() {
      if (this.href) return "a";
      return this.routerPath === "" ? "button" : "router-link";
    },

  },
};
</script>

<style scoped>
</style>