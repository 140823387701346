import axios, {$axios, catch_handler, then_handler} from "../axios";
import programDetails from "../../views/public/course/program-details";

export default {
    namespaced: true,
    state: {
        programDetails: { },
        // studentPrograms: []
    },
    mutations: {

    },
    actions: {
        async programDetails( {state}, params ){
            params = params || {};

            const then = then_handler( params,  ({data}) => {
                state.programDetails = data;
            });

            const _catch = catch_handler( params );

            return await axios( )
                .get( '/batch-details', {params: params.params} )
                .then(  then )
                .catch( _catch );
        },
        async programEnrollBefore( {state}, params ){
            params = params || {};

            const then = then_handler( params,  ({data}) => {
                state.programDetails = data;
            });

            const _catch = catch_handler( params );

            return await axios( )
                .get( '/program-enroll-before', {params: params.params} )
                .then(  then )
                .catch( _catch );
        },
        async enroll( {state}, params ){
            params = params || {};

            return await axios( )
                .post( '/student/participate', params.data )
                    .then( typeof params.success == 'function'
                        ? params.success : () => {}
                    ).catch( ({response}) =>
                        typeof params.error == 'function'
                            ? params.error( response ) : () => {} );

        },
        async updateCandidateType( {state}, params ){
            params = params || {};

            const programId = params.programId;

            return await axios( )
                .put( `/student/courses-edit/${programId}`, { candidate_type: params.candidateType } )
                    .then( typeof params.success == 'function'
                        ? params.success : () => {}
                    ).catch( ({response}) =>
                        typeof params.error == 'function'
                            ? params.error( response ) : () => {} );

        },
        async getStudentCourses( {state}, params ){
            params = params || {};

            function studentCoursesLoaded( data ){
                // if ( Array.isArray( data.availableBatch ) ) {
                //     state.studentPrograms = data.availableBatch;
                // }

                if( typeof  params.success == 'function' ) {
                    params.success( data )
                }
            }

            return await axios( )
                .get( '/student/courses', {params: params.params } )
                    .then( studentCoursesLoaded ).catch( ({response}) =>
                        typeof params.error == 'function'
                            ? params.error( response ) : () => {} );

        },

        async schedules({state,commit}, params ){
            const programId = params.params.program_id;
            return await axios( )
            .get(`/schedules/${programId}`)
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );
        },

        async getLectureDetails({state,commit}, params ){
            const lectureId = params.params.lectureId;
            return await axios( )
            .get(`/lecture-details/${lectureId}`)
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );
        },
        
        async contents({state,commit}, params ){
            return await $axios.get('/contents/'  + ( params.programId ) + '/' + ( params.serial ), params )
        },
        async viewContent({state,commit}, params ){
            return await $axios.get('/student/course/' + ( params.programId ) + '/content/'  + ( params.contentId ), params )
        },
        async contentMaterial({state,commit}, params ){
            return await $axios.get('/contents/'  + ( params.programId ) + '/' + ( params.materialId ), params )
        },
        async downloadInvoice({state, commit}, params = {}){
            params = params || {};

            return await axios().get(`/student/download-invoice/${params.data.program_id}`, {params: params.params})
                .then((res) => {
                    var FILE = window.URL.createObjectURL(new Blob([res.data]));
                    var docUrl = document.createElement('x');
                    docUrl.href = FILE;
                    docUrl.setAttribute('download', 'sample.pdf');
                    document.body.appendChild(docUrl);
                    docUrl.click();
                })
                .catch((error) => {
                    console.log(error);
                });
        }


    },
    getters: {
        studentPrograms ( state ) {
            return state.studentPrograms;
        },
    },
    modules: {

    }
}
