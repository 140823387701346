import axios from "../axios";

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        async list( {state}, params ){
            params = params || {};

            return await axios( ).get( '/student/exams', { params: params.params} )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async questions( {state}, params ){
            params = params || {};

            return await axios( ).get( '/student/exam-question', { params: params.params } )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async participate( {state}, params ){
            params = params || {};

            return await axios( ).post( '/student/participate-in-exam', params.data )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async answer( {state}, params ){
            params = params || {};

            return await axios( ).post( '/student/exam-answer', params.data )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async finish( {state}, params ){
            params = params || {};

            return await axios( ).post( '/student/exam-submission', params.data )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async result( {state}, params ){
            params = params || {};



            return await axios( ).get( '/student/exam-result', {params: params.params} )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },
        async answers( {state}, params ){
            params = params || {};

            return await axios( ).get( '/student/show-answers', {params: params.params} )
                .then( typeof params.success == 'function'
                    ? params.success : () => {}
                ).catch( ({response}) =>
                    typeof params.error == 'function'
                        ? params.error( response ) : () => {} );

        },

    },
    getters: {

    },
    modules: {

    }
}
